// .swal2-title {
//     color: rgb(38, 36, 36);
//     font-size: 1.5rem;
//     font-weight: 500;
//     height: 60px;
// }

// .swal2-modal {
//     // width: 80vw;
//     // height: 80vh;
//     background-color: #eef0f1;
//     position: absolute;
//     top: 0;
//     left: 50%;
// }

.text-red {
    color: red;
}

.nav-buttons {
    border: none;
    background-color: transparent;
    color: rgb(38, 36, 36);
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.5rem;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.nav-buttons.active {
    background-color: #dc3545;
    color: white;
}

.nav-buttons:hover {
    color: #dc3545;
}

.nav-buttons.active:hover {
    color: white;
}

#swal2-html-container {
    --color-primary: #dc3545;

    label {
        color: rgb(38, 36, 36);
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    input {
        padding: 0.5rem;
        border: none;
        border-radius: 0.25rem;
        margin-bottom: 0.5rem;
        background-color: #ededed;
        border: 1px solid #d9d9d9;
    }

    #containerinputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 1rem;
        gap: 10px;
    }


}

#ConsorcioTgeneral {
    .nav-item label {
        color: rgb(38, 36, 36);
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        width: 15%;
    }

    .nav-item input,
    .nav-item select {
        padding: 0.5rem !important;
        border: none !important;
        border-radius: 0.25rem !important;
        margin-bottom: 0.5rem !important;
        background-color: #ededed !important;
        border: 1px solid #d9d9 !important;
        width: 85% !important;
        color: #202020 !important;

        &[type="checkbox"] {
            width: 20px !important;

            &[type="checkbox"]:checked {
                background-color: #dc3545 !important;
            }

        }
    }

    .nav-item div {
        width: 85%;
        gap: 10px;
    }

    .nav-item div input {
        width: 10%;
    }

    .nav-item div input.form-check-input {
        width: 3%;
    }

    .nav-item div input.form-check-input:checked {
        background-color: #dc3545;
    }
}

#ConsorcioTlmtGral {
    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        width: 100%;
        transform: scale(0.9);
    }

    ul li {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 40%;
    }

    ul li label {
        color: rgb(38, 36, 36);
        width: 25%;
    }

    ul li input {
        width: 75%;
    }
}

#ConsorcioTlmtLiga {
    .d-flex label {
        color: rgb(38, 36, 36);
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    .d-flex select {
        padding: 0.5rem;
        border: none;
        border-radius: 0.25rem;
        margin-bottom: 0.5rem;
        background-color: #ededed;
        border: 1px solid #d9d9d9;
        width: 55%;
    }
}

#ConsorcioTmjsTicket {
    padding: 7vw;

    span {
        background-color: #d9d9d9;
        border: 1px solid #bdbaba;
        border-radius: 0.25rem;
        padding: 1.5rem 1rem;
        margin-bottom: 0.5rem;
        color: rgb(38, 36, 36);
        font-weight: 400;
        text-transform: uppercase;
    }
}

// element.style {
//     width: 1200px;
//     transform-origin: 477px 197px;
// }

:where(.css-dev-only-do-not-override-sk7ap8).ant-modal {
    top: 0px;
}



@media (max-width: 1280px) {
    .ant-modal-wrap .ant-modal {
        width: 95vw !important;
        max-height: 90vh !important;
        transform: scale(0.9);
    }

    .nav-buttons {
        font-size: 0.8rem;
    }

    .ant-modal-wrap .ant-modal-content {
        width: 100%;
    }

    .labelGeneral {
        font-size: 0.8rem !important;
    }
}

@media (max-width: 576px) {}

@media (max-width: 768px) {}

@media (max-width: 992px) {}

@media (max-width: 1600px) {}


// usuario table

#usuariosContainer {
    td.expand {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    td .button-expand {
        width: 20px;
        height: 20px;
        background-color: transparent;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    td .button-expand i {
        color: #08a5f3;
        transition: all 0.2s ease-in-out;
    }

    td .button-expand:hover i {
        color: #dc3545;
    }

    td .button-expand:hover {
        background-color: transparent;
    }

    div.userSelected {
        background-color: #2F4858;
        color: white;
        border-radius: 0.25rem;
        border: 1px solid #d9d9d9;
        padding: 0.3rem;

        p {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    select.crear-usuario-grupo {
        width: 100px !important;
        background-color: #2d2e42;
        color: white;

    }

    tr.row-selected {
        background-color: #2F4858 !important;
        color: rgb(134, 10, 10) !important;
    }
}

#terminalesContainer {
    select.ticket {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 0.25rem;
        margin-bottom: 0.5rem;
        background-color: #ededed;
        border: 1px solid #d9d9d9;
    }

}

.dropdown-menu {
    button {
        width: 100% !important;
        background-color: transparent !important;
        color: #252525 !important;
        border: none !important;
        padding: 0.5rem !important;
        text-align: left !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
        cursor: pointer !important;

    }
}

#config_tables {
    table {
        width: 100% !important;
        border-collapse: collapse;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        border: 2px solid #202020;
        display: table;
        table-layout: fixed;

        thead {
            background-color: #d2143a;
            color: #fff;
            border-bottom: 2px solid #202020;

            tr {
                width: 100%;

                th {
                    width: fit-content;

                    &:not(:last-child) {
                        border-right: 2px solid #202020;
                    }
                }
            }
        }

        tbody {
            color: #fff;

            tr {
                width: 100%;

                td {
                    width: fit-content;

                    &:nth-child(2) {
                        font-weight: normal;
                        text-align: center;
                        padding-left: 3px;
                    }

                    * {
                        text-align: center;
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;
                        color: #fff;
                    }

                    div {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        input {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &:not(:last-child) {
                        border-right: 2px solid #202020;
                    }
                }

                &:nth-child(odd) {
                    background-color: #2d2e42;
                }

                &:nth-child(even) {
                    background-color: #2d2e42db;
                }
            }
        }

    }
}