.lines-games-vertical__container {

    .nav-lines {
        height: 10vh;
        max-height: 100px;
        background-color: #202020;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;

        span.nav-lines__date--day {
            font-size: 4rem;
            color: #fff;
            margin-left: 1rem;
            font-weight: 500;

        }

        img {
            height: 80%;
            width: auto;
        }
    }

    color: #202020;

    #table-dinamic-responsive {
        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;



            * {
                font-size: 12px;
                text-align: center !important;
                font-family: 'Roboto', sans-serif;
                font-weight: 600;
            }

            thead {
                background-color: #dc3545;
                color: #fff;

                tr {
                    th {
                        padding: 8px;
                        text-align: left;
                        border: 2px solid #222020;
                        text-transform: uppercase;
                        font-weight: 900;

                        &.border-end {
                            border-right: 3px solid #202020 !important;
                        }
                    }
                }
            }

            tbody {
                tr {
                    height: 60px !important;
                    overflow-y: hidden !important;
                    border: 2px solid #222020;

                    img {
                        width: 29px;
                        height: 29px;
                        object-fit: cover;
                    }

                    &:nth-child(even) {
                        background-color: #f2f2f2;
                    }

                    td {
                        padding: 0px;
                        text-align: left;
                        border: 1px solid #00000023;
                        height: 50%;

                        &.games_container {
                            padding-left: 20px !important;
                            width: 300px !important;
                        }

                        div.container_inputs {
                            height: 60px !important;

                            div {
                                height: 50% !important;

                                input {
                                    width: 100%;
                                    height: 100%;
                                    border: none;
                                    background-color: transparent;
                                    text-align: center;

                                    &:focus {
                                        outline: none;
                                    }

                                }

                                &.approved {
                                    background-color: rgba(85, 207, 114, 0.5921568627) !important;
                                }

                                &.pending {
                                    background-color: rgba(245, 92, 92, 0.5921568627) !important;
                                }

                                &:nth-child(2) {
                                    border-top: rgba(85, 207, 114, 0.8) 1px solid;
                                }

                                &.approved.bg-opacity-50 {
                                    background-color: rgba(19, 234, 69, 0.452) !important;
                                }

                                &.manual {
                                    background-color: rgba(0, 0, 0, 0.8) !important;
                                    color: #f2f2f2 !important;
                                }
                            }
                        }

                        &.border-end {
                            border-right: 3px solid #202020 !important;
                        }
                    }
                }
            }
        }
    }
   
    .lines-games-vertical__table_league {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        label {
            font-size: 2.5rem;
            font-weight: 600;
            font-weight: 700;
            text-transform: uppercase;
        }

        span {
            font-size: 2.5rem;
            font-weight: 600;
            font-weight: 700;
            text-transform: uppercase;
        }

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .lines-games-vertical__table__period__title {
        width: 100%;
        display: flex;
        justify-content: center;

        span {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 1rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    font-family: 'Roboto',
    sans-serif !important;
}