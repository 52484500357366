.table-limit {
  border-collapse: separate !important;
  border-spacing: 0px;
  /* padding: 10px; */
  background-color: red;
  width: 100%;
  margin-left: 45px;
  /* border: 3px solid black; */
  border-left: 3px solid black;

}

.table-container-limit {
  overflow-x: auto;
  width: initial;
  /* margin: 0 auto; */
  padding: 50px 50px 50px 0px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  height: 550px;
}

.table-container-limit th tr {
  background-color: aliceblue;
  
}

.table-container-limit::-webkit-scrollbar {
  width: 12px;
}

/* Estilo del "thumb" (la parte arrastrable del scrollbar) */
.table-container-limit::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  /* Color del thumb */
  border-radius: 10px;
  /* Bordes redondeados del thumb */
  border: 5px solid #4d4d4d;
  /* Borde del thumb */
}

/* Estilo cuando pasas el ratón sobre el thumb */
.table-container-limit::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Estilos para casillas deshabilitadas */
.casilla-header-limit.disabled,
.casilla-body-limit.disabled {
  background-color: #ccc !important;
  /* Color de fondo para deshabilitadas */
  color: #666 !important;
  /* Color de texto para deshabilitadas */
  /* Otros estilos según sea necesario */
}