:root {
    --background-color: #bdd4f4;
    --text-color: #6d6d6d;
    --text-color-secondary: #163575;
    --buttons-gradient: linear-gradient(180deg, #DCEFFF 36%, #CAE3FF 37%, #CAE3FF 100%);
    --buttons-gradient-disabled: #d6e5fc;
}

#LayoutClient {
    background-color: #bdd4f4 !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--text-color);

    * {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
    }

    .bg-card {
        background-color: #bdd4f4 !important;
        border-radius: 5px;
        border: solid 1px #0d2d6a;
        padding: 5px;
        font-size: 14px !important;
    }

    #table_bets_render_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        background-color: #edeff1;
        margin-top: 10px;
    }

    .navbar-brand.active {
        border: 2px solid #FBAE72;
    }

    .footer_container {
        border: 1px solid var(--text-color-secondary);
        height: 60px;
    }

    .footer_button {
        background: var(--buttons-gradient);
        border-radius: 10px;
        color: var(--text-color-secondary);
        border: 1px solid var(--text-color-secondary);
    }

    .footer_button:disabled {
        background: var(--buttons-gradient-disabled);
    }

    .button_to_add_bet button {
        position: absolute;
        top: 50%;
        left: 50%;
        background: var(--buttons-gradient) !important;
        border-radius: 10px;
    }

    #left-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        select.betType {
            border: none;
            font-weight: bold;
            text-align: center;
            text-align-last: center;
        }
    }

    #right-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        position: relative;

        * {
            // font-size: 14px !important;
            font-weight: 700;
        }

        .right_button {
            background: var(--buttons-gradient);
            border-radius: 10px;
            color: var(--text-color-secondary);
            border: 1px solid var(--text-color-secondary);
            gap: 3px;
        }

        .right_button a {
            width: 100% !important;

        }

        .Tables_right_container {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            height: 55vh;
            overflow: auto;
            gap: 10px;
        }

        .table-responsive {
            // min-width: 463px !important;
            // overflow: hidden;
        }

        // .Tables_right_container::-webkit-scrollbar {
        //     width: 15px;
        //     height: 15px;
        // }

        // .Tables_right_container::-webkit-scrollbar-track {
        //     background: #f1f1f1;
        // }

        // .Tables_right_container::-webkit-scrollbar-thumb {
        //     background: #1d6a0d;
        // }

        .table-responsive::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }

        .table-responsive::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        .table-responsive::-webkit-scrollbar-thumb {
            background: #0d2d6a;
        }

        .Tables_right_container .table-responsive table {
            width: 100%;
            border: 1px solid #0d2d6a;
            color: #000;

            thead {
                width: 100%;

                th {
                    width: 100%;
                    max-width: fit-content !important;
                    padding: 0.5rem !important;
                    font-size: 11px !important;
                    font-weight: 800 !important;
                    text-transform: uppercase !important;
                    background-color: #0d2e6abe !important;
                    color: #f1f1f1;
                }
            }

            tbody {
                td {
                    padding: 0.5rem !important;
                    font-size: 11px !important;
                    font-weight: 800 !important;
                    text-transform: uppercase !important;
                    background-color: #fff !important;

                    * {
                        width: fit-content !important;
                    }
                }
            }
        }

    }

    #middle-totals {
        display: none !important;
    }

    @media (max-width: 768px) {
        justify-content: flex-start;
        width: 100vw !important;
        min-height: 100vh !important;
        overflow-x: hidden !important;

        #main-container {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: start;
            height: 100%;
            width: 100%;
            margin-top: 10px;
            flex-wrap: wrap;

            #left-container {
                display: flex;
                height: 100% !important;
                width: 50%;
                padding: 0 !important;
                padding-right: 2px !important;
                min-height: 220px !important;

                .bg-card {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;

                    justify-content: start;
                    align-items: start;

                    * {
                        font-size: 11px !important;
                    }

                    .ticket-top {
                        border-bottom: none;
                        margin: 0;
                        padding: 0;
                        display: flex !important;
                        flex-direction: row !important;
                        flex-wrap: wrap !important;

                        div {
                            padding: 0 !important;

                            div {
                                p {
                                    color: #333;
                                    font-weight: 700;
                                }

                                input,
                                select {
                                    max-width: 100px;
                                }
                            }
                        }

                        .border-bottom-ticket {
                            display: none;
                        }
                    }

                    .ticket-bottom {
                        display: none !important;
                        // width: 50% !important;
                        // display: flex !important;
                        // flex-direction: column !important;
                        // justify-content: center !important;
                        // height: 100% !important;
                    }

                }
            }

            #middle-container {
                padding: 0 !important;
                padding-left: 2px !important;
                display: flex;
                flex-direction: column;
                height: 100% !important;
                min-height: 220px !important;
                width: 50%;

                * {
                    font-size: 10px !important;
                }

                .bg-card {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    min-height: 220px !important;
                    justify-content: space-between !important;
                    align-items: center !important;
                    padding: 0 !important;

                    >div {
                        height: 80% !important;

                        div#header_container {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: nowrap;
                            gap: 0;
                            padding: 2px 10px !important;

                            div {
                                padding: 0 !important;

                                input {
                                    max-width: 10px;
                                }

                                label {
                                    font-size: 9px !important;
                                }


                            }
                        }

                        >div#table_bets_render_container {
                            table {}
                        }
                    }

                    #middle-totals {
                        background-color: #0d2d6a;
                        display: flex !important;
                        width: 100%;
                        height: 20% !important;
                        align-items: center !important;
                        justify-content: center !important;

                        ul {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: nowrap;
                            gap: 0;
                            padding: 3px 7px !important;
                            list-style: none;
                            width: 100% !important;
                            height: 100% !important;
                            margin: 0 !important;

                            li {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                flex-wrap: nowrap;
                                gap: 0;
                                padding: 0 !important;

                                span {
                                    font-size: 9px !important;
                                    font-weight: 700 !important;
                                    color: #fff !important;
                                }
                            }
                        }
                    }
                }


            }

            #right-container {
                margin-top: 4px;
                width: 100% !important;
                padding: 0 !important;

                >div {
                    padding: 0 !important;

                    >div:nth-child(1) {
                        margin: 0 !important;
                        padding: 0 8px !important;
                        padding-top: 8px !important;
                    }

                    >div {
                        // padding: 0 !important;
                    }
                }

                #table-lines-responsive {
                    display: flex !important;
                    flex-direction: column !important;
                    justify-content: start !important;
                    align-items: start !important;
                    flex-wrap: nowrap !important;
                    gap: 0 !important;
                    padding: 0 !important;
                    width: 100% !important;
                    height: 100% !important;
                    // background-color: #5f0d6a !important;

                    table {
                        width: 100% !important;
                        border: 2px solid #0d2d6a !important;

                        * {
                            font-size: 10px !important;
                        }

                        thead {
                            border-bottom: 2px solid #0d2d6a !important;

                            tr {
                                padding: 0 !important;
                                white-space: nowrap !important;
                                font-size: 10px !important;

                                th {
                                    padding: 0 5px !important;
                                    white-space: nowrap !important;
                                    font-size: 10px !important;
                                }
                            }
                        }

                        tbody {
                            border: none !important;

                            tr {
                                padding: 0 !important;
                                white-space: nowrap !important;
                                border: none !important;

                                td {
                                    padding: 0 5px !important;
                                    white-space: nowrap !important;
                                    border: 1px solid #0d2d6a !important;
                                    font-size: 10px !important;
                                }
                            }

                        }
                    }
                }
            }
        }

        .footer_container {
            display: flex !important;
            flex-direction: row !important;
            flex-wrap: nowrap !important;
            overflow-x: auto !important;
            border: none !important;
            z-index: 10 !important;
            position: fixed !important;
            bottom: 10px !important;
            left: 0 !important;
            right: 0 !important;
            width: 100% !important;
            margin: 0 auto !important;
            background-color: #fff !important;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
            padding: 10px !important;

            button,
            select {
                white-space: nowrap !important;

                &:disabled {
                    display: none;
                }
            }
        }

        header {
            padding: 0 !important;

            >div {
                padding: 0 !important;

                >div {
                    padding: 0 !important;
                }
            }

            nav.navbar {
                display: flex !important;
                flex-wrap: nowrap !important;
                overflow-x: auto !important;
                flex-direction: row !important;
                justify-content: start !important;
                align-items: center !important;
                padding: 0 !important;
                width: 100%;

                a {
                    padding: 5px 10px !important;
                    margin-right: 0 !important;

                    &.active {
                        border: none !important;
                        background-color: #0d2d6a;
                    }
                }
            }
        }


    }
}



.bet-selected {
    background: linear-gradient(180deg, #ffdbb5 0%, #ffb65a 42%, #ffe794 85%, #fff 100%);
}

.border-bottom-ticket:before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: -6px;
    border-radius: 0 18px 18px 0;
    border-top: solid 1px #0d2d6a;
    border-right: solid 1px #0d2d6a;
    border-bottom: solid 1px #0d2d6a;
    width: 16px;
    height: 30px;
    background-color: #bdd4f4;
    /* background-color: black; */
}

.border-bottom-ticket:after {
    content: '';
    position: absolute;
    bottom: -15px;
    right: -7px;
    border-radius: 18px 0 0 18px;
    border-top: solid 1px #0d2d6a;
    border-left: solid 1px #0d2d6a;
    border-bottom: solid 1px #0d2d6a;
    width: 18px;
    height: 30px;
    background-color: #bdd4f4;
}



.ticket-top {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 3px dashed #eeeff3;
}

.ticket-top {
    height: 60% !important;
    padding-top: 15px;
}

.ticket-bottom {
    height: 40% !important;
}

.vertical__table__period__title {
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    color: #202020 !important;
}