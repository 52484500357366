#container {
  height: 450px;
  width: 370px;
  background-color: white;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 15% 71% 2% 12%;

}

#topBar {
  background-color: #CE5151;
  border-radius: 10px 10px 0px 0px;
  display: grid;
  grid-template-columns: 20% 60% 20%;
}

#onlineRow {
  display: flex;
  flex-flow: row;
  align-items: center;
}

#greenCircle {
  height: 10px;
  width: 10px;
  background-color: #81c784;
  border-radius: 5px;
  border: 2px solid #fff;
  margin-right: 5px
}

#imgColumn {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  grid-column-start: 1;
}

#textColumn {
  grid-column-start: 2;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding-left: 5px;
  width: 100%;
  color: #E3E3E3;
}

#textColumn2 {
  grid-column-start: 3;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 60px;
}

#messageBoard {
  width: 100%;
  overflow-y: scroll;
}

#bottomBar {
  display: grid;
  grid-template-columns: 85% 15%;
  border-radius: 0px 0px 10px 10px;

}

#input {
  border: 0;
  height: 100%;
  padding: 5px;
  border-radius: 0px 0px 10px 10px;

}

#bot-message {
  background-color: #E3E3E3;
  min-height: 35px;
  min-width: 20px;
  max-width: 200px;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 10px;
}

#bot-message-without-icon {
  background-color: #E3E3E3;
  min-height: 35px;
  min-width: 20px;
  max-width: 200px;
  margin-left: 41px;

  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 10px;
}

#bot-icon {
  background-color: #BA68C84D;
  height: 38px;
  width: 38px;
  margin-right: 3px;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#user-message {
  background-color: #686CC8;
  min-height: 35px;
  min-width: 20px;
  max-width: 200px;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 10px;
}

#sendBtn {
  border: '0'
}

#sendBtn:hover {
  color: #686bc844;
}

#optionBtn {
  background-color: #E3E3E3;
  min-height: 35px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 10px;
  font-size: 12px;
  border-radius: 20px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

#logoutBtn {
  background-color: #A6A6A6;
  min-height: 25px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  // border-radius: 1px;
  font-size: 10px;
  border: 0;
  color: #fff;

  border-radius: 20px;
}

#selectOption {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  column-gap: 10px;
  row-gap: 5px;
  margin-top: 5px;
  margin-left: 41px;
}

#optionBtn:hover {
  color: #CE5151;
  cursor: pointer;
  border-color: #CE5151;
}