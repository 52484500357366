/* TableBody.css */
.casilla-body-limit {
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  /* border-radius: 5px; */
  background-color: #dc3545 !important;
  color: white;
  padding: 8px;
  text-align: center;
  margin-right: 5px;
  font-weight: bold;
  /* Agrega un margen derecho para separar las casillas */
}

/* .casilla-body-limit:first-child {
 
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
  
} */

.casilla-body-limit.first-column {
  /* Añade tus estilos específicos para la primera columna aquí */
  background-color: red !important;
  /* Cambia el color de fondo según tus necesidades */
  font-weight: bold;
  /* Puedes ajustar el estilo de fuente según tus necesidades */
  /* Otros estilos según sea necesario */
  /* border: 3px solid black; */
}