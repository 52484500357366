#ieautomtico_container{
  select,input {
    height: 40px    !important;
    border: 1px solid #3f3f3f !important;
    border-radius: 4px !important;
    padding: 0 10px !important;
    font-size: 14px !important;
    margin: 0px !important;
    color: #333 !important;
  }
  label {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #3e3e3e!important;
    margin: 0px !important;
    width: fit-content !important;
  }
  button {
    width: fit-content !important;
    margin: 0 auto !important;
    display: block !important;
    background-color: transparent !important;
    color: #000000 !important;
    border: 1px solid #131313 !important;
    border-radius: 4px !important;
    padding: 10px 20px !important;
    height: 40px    !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    transition: all 0.3s !important;
    &:hover {
      background-color: #dc3545 !important;
      color: #fff !important;
    }
  }

  table{
    width: 100% !important;
    thead {
        background-color: #dc3545 !important;
        border: 2px solid #131313 !important;
        color: #fff !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        text-align: center !important;
        tr {
            th {
                padding: 10px 20px !important;
            }
        }
        tr {
            th:first-child {
                border-top-left-radius: 4px !important;
                border-bottom-left-radius: 4px !important;
            }
            th:last-child {
                border-top-right-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
            }
        }
    }
    tbody{
        color: #fff !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        text-align: center !important;
        border: 2px solid #131313 !important;
        
        tr {
            td {
                padding: 10px 20px !important;
            }
        }
        tr {
            td{
                padding: 10px 20px !important;
                text-align: center !important;
                button {
                    width: fit-content !important;
                    margin: 0 auto !important;
                    display: block !important;
                    background-color: #dc3545 !important;
                    color: #f3f3f3 !important;
                    border: 1px solid #131313 !important;
                    border-radius: 4px !important;
                    padding: 10px 20px !important;
                    height: 40px    !important;
                    font-size: 14px !important;
                    font-weight: 600 !important;
                    cursor: pointer !important;
                    transition: all 0.3s !important;
                    &:hover {
                        background-color: #3f3f3f !important;
                        color: #fff !important;
                    }
                }
            }
           
        }
        tr:nth-child(even) {
            border-top: 1px solid #888888 !important;
            background-color: #5f5f5f;
        }
        tr:nth-child(odd) {
            background-color: #464646;
            border-top: 1px solid #888888 !important;
        }
        tr:hover {
            background-color: #3f3f3f;
        }
    }
  }
}