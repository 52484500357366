:root {
    --background-color: #bdd4f4;
    --text-color: #6d6d6d;
    --text-color-secondary: #163575;
    --buttons-gradient: linear-gradient(180deg, #DCEFFF 36%, #CAE3FF 37%, #CAE3FF 100%);
    --buttons-gradient-disabled: #d6e5fc;
}

#LayoutClientWeb {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--text-color);
    min-height: 100vh;

    #table_bets_render_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        background-color: #edeff1;
        margin-top: 10px;
    }

    .navbar-brand.active {
        border: 2px solid #FBAE72;
    }

    .footer_container {
        border: 1px solid var(--text-color-secondary);
        min-height: 160px;
        gap: 10px;
        padding: 4px;
    }

    .footer_button {
        background: var(--buttons-gradient);
        border-radius: 10px;
        color: var(--text-color-secondary);
        border: 1px solid var(--text-color-secondary);
        white-space: nowrap;
    }

    .footer_button:disabled {
        background: var(--buttons-gradient-disabled);
    }

    // .button_to_add_bet button {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     background: var(--buttons-gradient) !important;
    //     border-radius: 10px;
    // }

    #left-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        select.betType {
            border: none;
            font-weight: bold;
            text-align: center;
            text-align-last: center;
        }
    }

    #right-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        position: relative;

        .expand_button {
            position: absolute;
            top: 0;
            right: 0;
            background: var(--buttons-gradient);
            border-radius: 10px;
            color: var(--text-color-secondary);
            border: 1px solid var(--text-color-secondary);
            gap: 3px;
        }


        .right_button {
            background: var(--buttons-gradient);
            border-radius: 10px;
            color: var(--text-color-secondary);
            border: 1px solid var(--text-color-secondary);
            gap: 3px;
        }

        .right_button a {
            width: 100% !important;

        }

        .Tables_right_container {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            height: 55vh;
            overflow: auto;
            gap: 10px;
        }

        .table-responsive {
            min-width: 463px !important;
        }

        .Tables_right_container::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        .Tables_right_container::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        .Tables_right_container::-webkit-scrollbar-thumb {
            background: #888;
        }

        .Tables_right_container::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .table-responsive::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        .table-responsive::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        .table-responsive::-webkit-scrollbar-thumb {
            background: #888;
        }

        .Tables_right_container table {
            height: 45vh !important;
        }

    }

    .bet-selected {
        background: linear-gradient(180deg, #ffdbb5 0%, #ffb65a 42%, #ffe794 85%, #fff 100%);
    }

    .border-bottom-ticket:before {
        content: '';
        position: absolute;
        bottom: -15px;
        left: -6px;
        border-radius: 0 18px 18px 0;
        border-top: solid 1px #0d2d6a;
        border-right: solid 1px #0d2d6a;
        border-bottom: solid 1px #0d2d6a;
        width: 16px;
        height: 30px;
        background-color: #bdd4f4;
        /* background-color: black; */
    }

    .border-bottom-ticket:after {
        content: '';
        position: absolute;
        bottom: -15px;
        right: -7px;
        border-radius: 18px 0 0 18px;
        border-top: solid 1px #0d2d6a;
        border-left: solid 1px #0d2d6a;
        border-bottom: solid 1px #0d2d6a;
        width: 18px;
        height: 30px;
        background-color: #bdd4f4;
    }

    .bg-card {
        background-color: #bdd4f4;
        border-radius: 5px;
        border: solid 1px #0d2d6a;
        padding: 5px;
        font-size: 14px !important;
    }

    .ticket-top {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 3px dashed #eeeff3;
    }

    .ticket-top {
        height: 60% !important;
        padding-top: 15px;
    }

    .ticket-bottom {
        display: none;

        &.show {
            display: block;
        }
    }


}

.modal_reports_webusers {
    width: 100vw !important;

    * {
        font-size: 12px !important;

        div {
            padding: 0 !important;
            margin: 0 !important;


        }
    }

    table.table-mobile {
        width: 100% !important;
        border: 2px solid #0d2d6a !important;

        thead {
            width: 100%;
            background-color: #0d2d6a;
            color: #fff;

            tr {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                th {
                    width: 100%;
                    text-align: center;
                    font-weight: 400 !important;
                    font-size: 12px !important;
                    padding: 8px 0 !important;
                    border: none !important;
                    // bordered
                    border-right: 1px solid #fff !important;
                    padding: 0 5px !important;
                }
            }
        }



    }
}

.reportes-modal__container {
    * {
        font-size: 12px !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .ant-modal-content {
        transform: scale(1.15);
        border-radius: 2px !important;

        .ant-modal-header {
            background-color: #f2f2f2 !important;
            border-radius: 2px !important;
            border-bottom: 1px solid #fff !important;
            padding: 10px !important;
        }

        button {
            border-radius: 2px !important;
            top: 3px !important;
            right: 5px !important;
            color: #000 !important;

            svg {
                color: #000 !important;

                font-size: 20px !important;
            }
        }
    }

    // mobile view
    .reportes-modal {
        display: flex;
        flex-direction: column;
        padding: 0 !important;

        .reportes-modal__menu {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            .reportes-modal__menu-item {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 5px;
                padding: 5px 10px !important;
                border-radius: 5px;
                background-color: #fff;
                color: #0d2d6a;
                font-weight: 600;
                cursor: pointer;
                transition: all .3s ease;

                &.active {
                    background-color: #0d2d6a;
                    color: #fff;
                }
            }
        }

        .reportes-modal__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 0 10px;
            width: 100% !important;

            .reportes-modal__content-item {
                table.table-tickets-webusers {
                    width: 90vw !important;
                }
            }
        }
    }
}