#table-lines-responsive,
#table-matchups-lines-responsive {
  display: flex;
  flex-direction: column;
  background-color: #fff !important;

  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #0d2d6a;
  }

  width: 100% !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;

  .table-line {
    display: flex;
    width: 100% !important;

    .container-div {
      flex: 1;


      .header-div {
        display: flex;
        justify-content: center;
        width: 100% !important;
        padding: 0 10px !important;
        white-space: nowrap !important;
        background-color: #00aaff;
        color: #f1f1f1;
        font-size: 11px !important;
        font-weight: 600 !important;
        height: 24%;
      }

      .body-lines {
        padding: 0 10px !important;
        white-space: nowrap !important;
        width: 100% !important;
        color: #f1f1f1;
        font-size: 11px !important;
        font-weight: 600 !important;
        height: 76%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        img {
          width: 15px !important;
          height: 15px !important;
          object-fit: contain !important;
        }

        div.line-container {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #666;
          border: 1px solid #333 !important;
          border-radius: 8px;
          width: fit-content;
          padding: 2px 5px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          height: 50%;

          &:hover {
            background-color: #535252;
          }
        }

        div.team-container {
          background-color: transparent !important;
          border: none !important;
          padding: 0 !important;
          display: flex;
          align-items: center;
          gap: 5px;
          height: 50%;
        }
      }

      background-color: #0b2740 !important;

      //  div.teams {
      //    display: flex;
      //    flex-direction: column;
      //    width: 100% !important;
      //    padding-left: 5px;

      //    div {
      //      display: flex;
      //      justify-content: start;
      //      align-items: center;

      //      img {
      //        width: 20px !important;
      //        height: 20px;
      //        object-fit: cover;
      //      }


      //      span {
      //        font-size: 11px !important;
      //        font-weight: 500 !important;
      //        text-transform: uppercase !important;
      //        white-space: nowrap !important;
      //        width: 100% !important;
      //        color: #fff;
      //      }
      //    }
      //  }
    }

    .container-div.time-game {
      min-width: 220px !important;
    }
  }


  //  table {
  //    width: 100%;

  //    * {
  //      font-size: 12px !important;
  //      text-align: center !important;
  //      font-family: 'Roboto', sans-serif;
  //      font-weight: 600;
  //    }

  //    thead {


  //      tr {
  //        th {}
  //      }
  //    }

  //    tbody {
  //      tr {
  //        height: 60px !important;
  //        overflow-y: hidden !important;
  //        border: 2px solid #222020;

  //        img {
  //          width: 15px;
  //          height: 15px;
  //          object-fit: cover;
  //        }

  //        &:nth-child(even) {
  //          background-color: #f2f2f2;
  //        }

  //        td {
  //          padding: 0px;
  //          text-align: left;
  //          border: 1px solid #00000023;
  //          //  height: 50%;

  //          div.teams {
  //            white-space: nowrap;
  //            overflow: hidden;
  //          }

  //          div.container_inputs {
  //            //  height: 60px !important;

  //            div {

  //              //  height: 50% !important;
  //              span {
  //                white-space: nowrap !important;
  //              }
  //            }
  //          }


  //        }
  //      }
  //    }

  //  }
}

#table-matchups-lines-responsive {
  .line-container {
    height: 75% !important;
  }

  .header-div,
  .body-lines {
    height: auto !important;
  }

  .body-lines {
    margin: .5rem 0;
  }
}