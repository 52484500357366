/*tbody*/

.table-body-container tr {
    background-color: rgb(110, 112, 114);  
}

.table-body-container td {
    text-align: center;
    color: white;  
    font-weight: bolder;
}
