
.title-container {
    /* text-align: center; */
    /* margin: 20px 0; */

    color: slategrey;
    font-family: 'Poppins', sans-serif;
    margin: 20px;
   
  }
  
  .main-title {
    font-family: 'Poppins', sans-serif; /* Utiliza la fuente Poppins o la que prefieras */
    /* color: #333; /* Color del texto */
    font-size: 36px; /* Tamaño del texto */
    font-weight: 600; /* Grosor de la fuente */
    margin: 0; /* Elimina el margen por defecto */
    
    letter-spacing: 2px; /* Espaciado entre letras */
  }
   