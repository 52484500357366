tr.active {
    background-color: #2d2e42 !important;
}

.modalConsorcio {

    table {
        width: 100% !important;
        border-collapse: collapse;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        border: 2px solid #202020;
        display: table;
        table-layout: fixed;

        thead {
            background-color: #d2143a;
            color: #fff;
            border-bottom: 2px solid #202020;

            tr {
                width: 100%;

                th {
                    width: fit-content;

                    &:not(:last-child) {
                        border-right: 2px solid #202020;
                    }
                }
            }
        }

        tbody {
            color: #fff;

            tr {
                width: 100%;

                td {
                    width: fit-content;

                    &:nth-child(2) {
                        font-weight: normal;
                        text-align: center;
                        padding-left: 3px;
                    }

                    * {
                        text-align: center;
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;
                        color: #fff;
                    }

                    div {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        input {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &:not(:last-child) {
                        border-right: 2px solid #202020;
                    }
                }

                &:nth-child(odd) {
                    background-color: #2d2e42;
                }

                &:nth-child(even) {
                    background-color: #2d2e42db;
                }
            }
        }

    }

    // }

    select {
        width: fit-content !important;
        height: 100% !important;
        background-color: #d2143a !important;
        border: none !important;
        padding: 3px 5px !important;
        border-radius: 5px !important;
        color: #fff !important;
        font-weight: bold !important;
        font-size: 12px !important;
        cursor: pointer !important;

        &.selectpicker {
            background: transparent !important;
            color: #F2F2F2 !important;

            &>option {
                background: #2d2e42 !important;
                color: #F2F2F2 !important;
            }
        }
    }
}