 #table-lines-responsive {
     table {
         width: 100%;

         * {
             font-size: 12px !important;
             text-align: center !important;
             font-family: 'Roboto', sans-serif;
             font-weight: 600;
         }

         thead {


             tr {
                 th {}
             }
         }

         tbody {
             tr {
                 height: 60px !important;
                 overflow-y: hidden !important;
                 border: 2px solid #222020;

                 img {
                     width: auto !important;
                     max-width: 15px !important;
                     height: 15px !important;
                     object-fit: contain !important;
                 }

                 &:nth-child(even) {
                     background-color: #f2f2f2;
                 }

                 td {
                     padding: 0px;
                     text-align: left;
                     border: 1px solid #00000023;
                     //  height: 50%;

                     div.teams {
                         white-space: nowrap;
                         overflow: hidden;
                     }

                     div.container_inputs {
                         //  height: 60px !important;

                         div {

                             //  height: 50% !important;
                             span {
                                 white-space: nowrap !important;
                             }
                         }
                     }


                 }
             }
         }
     }
 }

 @media screen and (max-width: 768px) {
     #table-lines-responsive {
         table:not(#table-theme-1) {
             width: 100%;
             display: flex !important;
             flex-direction: column;
             justify-content: center;

             * {
                 font-size: 12px !important;
                 text-align: center !important;
                 font-family: 'Roboto', sans-serif;
                 font-weight: 600;
             }

             thead {


                 tr {
                     th {}
                 }
             }

             tbody {
                 tr {
                     height: 60px !important;
                     overflow-y: hidden !important;
                     border: 2px solid #222020;

                     img {
                         width: 15px;
                         height: 15px;
                         object-fit: cover;
                     }

                     &:nth-child(even) {
                         background-color: #f2f2f2;
                     }

                     td {
                         padding: 0px;
                         text-align: left;
                         border: 1px solid #00000023;
                         //  height: 50%;

                         div.teams {
                             white-space: nowrap;
                             overflow: hidden;
                         }

                         div.container_inputs {
                             //  height: 60px !important;

                             div {

                                 //  height: 50% !important;
                                 span {
                                     white-space: nowrap !important;
                                 }
                             }
                         }


                     }
                 }
             }
         }
     }
 }