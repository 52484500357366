#reportsContainer {
  label {
    color: rgb(234, 232, 232) !important;
    font-size: 0.8rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  // div.container-fluid {
  //     background-color: rgb(64, 165, 224);
  // }
  .table-cxc input.table-cxc-input {
    width: 60px !important;
  
  }
  select,
  input {
    &:not(.w-100),
    &:not(.w-100) {
      width: 110px !important;
    }
    text-align: center;
    font-size: small !important;
  }


  input.form-check-input {
    width: 20px !important;
  }

  button {
    // background-color: #dc3545 !important;
    border: none !important;
    margin: 0 !important;
    // padding: 0 !important;
    border-radius: 0 !important;
  }

  button.active {
    background-color: #767dda !important;
    color: #f1f1f1 !important;
    font-weight: bold !important;
    border: #f3f3f3 1px solid !important;
  }

  input[type="checkbox"] {
    width: 15px !important;
    height: 15px !important;
  }

  input[type="button"].btn-secondary {
    width: fit-content !important;
  }

  // table * {
  //   font-size: 0.8rem !important;
  // }

  .h-screen {
    height: 80vh;
  }

  .bg-reportes-head,
  .bg-gray-800 {
    background-color: #2d2e42;
  }

  // #customers {
  //   font-family: Arial, Helvetica, sans-serif;
  //   border-collapse: collapse;
  //   text-align: center;
  //   width: 100%;
  // }

  // #customers td,
  // #customers th {
  //   border: 1px solid #ddd;
  //   padding: 8px;
  // }

  // #customers tr:nth-child(even) {
  //   background-color: #f2f2f2;
  // }

  // #customers tr:hover {
  //   background-color: #ddd;
  // }

  // #customers th {
  //   padding-top: 12px;
  //   padding-bottom: 12px;
  //   text-align: left;
  //   background-color: #dc3545;
  //   text-align: center;
  //   color: white;
  // }

  // #customers tfoot th {
  //   padding-top: 12px;
  //   padding-bottom: 12px;
  //   text-align: left;
  //   background-color: white;
  //   color: #2d2e42;
  //   text-align: center;
  //   border: #dc3545 1px solid !important;
  //   font-weight: bold !important;
  // }

}

tr.active {
  background-color: #2d2e42 !important;
}

#table_days {
  .input_days {
    background: transparent;
    border: none !important;
    width: 40px !important;
    font-size: 0.7rem !important;
  }

  // quitar flecha de suma y resta al input de tipo number
  .input_days::-webkit-outer-spin-button,
  .input_days::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.label_and_p_container {
  justify-content: center;
  align-items: center;

  label {
    font-size: 0.8rem !important;
    text-decoration: underline;
    font-weight: bold !important;
  }

  p {
    font-size: 1rem !important;
    font-weight: lighter !important;
    margin: 0 !important;
  }
}

.label_and_p_container.border-end {
  border-right: 1px solid #131313 !important;
}

.table_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #131313;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  background-color: #2d2e42;
  color: #f3f3f3;
  font-size: 0.8rem !important;
  font-weight: lighter !important;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 1rem !important;
    font-weight: lighter !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 1px solid #131313 !important;
    border-radius: 5px !important;
    background-color: #2d2e42;
    color: #f3f3f3;

    thead {
      background-color: #dc3545;
      color: #f3f3f3;
      font-weight: bold !important;
      border: 1px solid #131313 !important;
      border-radius: 5px !important;
    }

    tbody {
      background-color: #2d2e42;
      color: #f3f3f3;
      font-weight: lighter !important;
      border: 1px solid #131313 !important;
      border-radius: 5px !important;
    }
  }
}


#webuser_container {
  button.button {
    width: fit-content !important;
    height: fit-content !important;
    padding: 3px 5px !important;
    margin: 0 !important;
    border-radius: 5px !important;
    background-color: transparent !important;
    border: 1px solid #f2f2f2 !important;
    color: #f2f2f2 !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
  }

  button.button:hover {
    background-color: #131313 !important;
    color: #f3f3f3 !important;
  }

  button.carga_descarga {
    color: #f3f3f3 !important;
    border: 1px solid #202020 !important;
    border-radius: 5px !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    width: fit-content !important;
    height: fit-content !important;
    padding: 3px 5px !important;

    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
  button.carga_descarga:hover {
    background-color: #202020 !important;
    color: #f3f3f3 !important;
  }

  button.webuser_actions {
    background: none;

    // color: #f3f3f3 !important;
    border: 1px solid #fff !important;
    border-radius: 5px !important;
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    width: fit-content !important;
    height: fit-content !important;
    padding: 3px 5px !important;

    svg {
      width: 1.2rem !important;
      height: 1.2rem !important;
    }
    &:hover {
      background-color: #000 !important;
    //   color: #f3f3f3 !important;
    }
  }

}


.form-contacts {
  span {
    display: block;
    text-align: center;
    font-size: 1.3rem !important;
    font-weight: bold;
  }

  button {
    padding: .5rem 2rem !important;
  }
}


// td.negative-value {
//   color: #dc3545 !important;
//   font-weight: bold !important;
// }