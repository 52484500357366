.separador {
    height: 1px;
    background-color: #e5e5e5;
    margin-top: 20px;
    margin-bottom: 50px;
    width: 100%;
}

#navbarAdmin {
    height: 100vh;
    overflow-y: scroll;
}

#navbarAdmin::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

#navbarAdmin::-webkit-scrollbar-thumb {
    background: transparent;
}

#navbarAdmin::-webkit-scrollbar-track {
    background: transparent;
}