#bodyAdminSideBar {
  height: 100vh;
  width: 100%;

  #left_container {
    background-color: #212529;
    max-height: 100vh;
    min-width: 65px;
    width: 65px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  // customize scrollbar
  #left_container::-webkit-scrollbar {
    width: 10px;
  }

  #left_container::-webkit-scrollbar-track {
    background: #5b5b5b;
  }

  #left_container::-webkit-scrollbar-thumb {
    background: #dc3545;
    border-radius: 10px;
  }

  #left_container::-webkit-scrollbar-thumb:hover {
    background: #bb2d3b;
  }

  .backtoadmin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
  }

  .side_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .logos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }

  .logo img {
    width: 41.3px;
    // margin: 20px 10px;
    filter: grayscale(100%) drop-shadow(1px 1px 0px #bb2d3b);
  }

  .logo {}

  .logo img:hover {
    filter: grayscale(0%);
  }

  .logo a.active {
    border-left: 5px solid #dc3545;
    transform: scale(0.9);
  }

  .logo img.active {
    filter: grayscale(0%);
  }

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row {
    display: flex;
  }

  #right_container {
    width: 95%;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    // &::-webkit-scrollbar-track {
    //   background: #dc3545;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: #dc3545;
    //   border-radius: 10px;
    // }
  }

  #right_container .right-container-r {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    font-family: "Roboto", sans-serif;

    .button_game_update {
      width: 100%;
      background-color: #165cdf;
      color: #fff;
      border: 0;
      border-radius: 0;
      height: 50px;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  ul.nav {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .nav-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >label {
      font-size: 1.1rem;
      font-weight: bold;
      color: #dc3545;
    }

  }

  .nav-item.search,
  .nav-item.teams {
    width: 20%;
  }

  .nav-item.crear,
  .nav-item.borrar {
    width: 8%;
  }

  button.crear,
  button.borrar {
    width: 100%;
    height: 100%;
    background-color: #dc3545;
    color: aliceblue;
  }

  button.search {
    background-color: #dc3545;
    color: aliceblue;
  }

  .nav-item>label.text {
    width: 50%;
    font-size: 0.9rem;
    color: rgb(34, 31, 31);
  }

  .nav-item>label.text-date {
    color: rgb(0, 0, 0);
    font-size: 1.4rem;
  }

  .nav-item>select {
    width: 50%;
  }

  .nav-item>input {
    width: 60%;
    height: 100%;
    font-size: 0.9rem;
  }

  .nav-item>button.search {
    width: 35%;
    height: 100%;
    font-size: 0.9rem;
  }

  .nav>li a,
  .nav>li span {
    color: rgb(34, 31, 31);
  }

  .nav-link.active {
    background-color: #dc3545;
    color: #e7e4e4;
    border-radius: 5px;
  }

  tr>th.thead {
    background-color: #dc3545;
    color: #fff;
    border-right: 1px solid #fff;
    font-size: 12px !important;
    text-align: center !important;
    font-weight: 900 !important;
  }

  td.approved,
  td.playing,
  td div.approved,
  td div.playing {
    color: rgb(28, 27, 27);
    background-color: #9ae3ac;
    border-right: 1px solid #fff;
  }

  td.pending,
  td div.pending {
    color: rgb(28, 27, 27) !important;
    background-color: #f49999 !important;
    border-right: 1px solid #fff !important;
  }

  input.pending {
    color: rgb(28, 27, 27) !important;
    background-color: #f49999 !important;
  }

  input.approved {
    color: rgb(28, 27, 27) !important;
    background-color: #9ae3ac !important;
  }

  .input_line {
    border: none;
    border-bottom: #55cf72 1px solid !important;
  }

  .lineSelected {
    border: 3px solid #165cdf !important;
  }

  .nav-item.procesar {
    width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item.procesar button {
    width: 45%;
  }

  .nav-item.procesar select {
    width: 45%;
    font-size: 0.9rem;
  }

  .nav-item.grupo {
    width: 20%;
  }

  .nav-item.grupo button {
    width: 100%;
  }

  .nav-item.tplinea {
    width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .nav-item.tplinea label,
  .nav-item.tplinea select {
    width: 45%;
    font-size: 0.9rem;
  }

  .nav-item.periodo {
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .nav-item.periodo label {
    width: 45%;
    font-size: 0.9rem;
  }

  .nav-item.periodo button {
    width: 45%;
    font-size: 0.8rem;
  }

  .nav-item.grupo2 {
    width: 20%;
  }

  .nav-item.grupo2 button {
    width: 47%;
  }

  .nav-item2 {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

#hyr_container {
  height: 100vh;

  .nav_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;

    .box-hyl-nav {
      padding: 5px;
      margin: 0;
      border: #f9f9f9 1px solid;
      box-shadow: -4px 2px 5px #96959530;
      border-radius: 5px;
      background-color: #fff;

      ul {
        display: flex;
        // background-color: #165cdf; 
        gap: 0.5rem;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;

          span.versus_text {
            font-size: 1.1rem;
            font-weight: bold;
            color: #dc3545;

          }

          input {
            width: fit-content;
            height: fit-content;
            font-size: 12px;
          }

          button {
            width: fit-content;
            height: fit-content;
            font-size: 12px;
          }

          select {
            width: fit-content;
            height: fit-content;
            font-size: 12px;
          }

          label {
            width: fit-content;
            height: fit-content;
            font-size: 12px;
          }

          a {
            width: fit-content;
            height: fit-content;
            font-size: 12px;
            border: 1px solid #dc3545;
            padding: 5px;
            border-radius: 5px;
          }

          a:hover {
            cursor: pointer;
            color: #e7e4e4 !important;
            background-color: #dc3545 !important;
          }

          label.server_time_date {
            font-size: 20px;
            font-weight: bold;
            width: fit-content;
            height: fit-content;
            padding: 0;
            margin: 0;
          }

          label.league_name {
            font-size: 28px;
            font-weight: bold;
            width: fit-content;
            padding: 0;
            margin: 0;
            // animation: pulse 3.5s infinite;
          }

          button.button {
            width: fit-content;
            height: fit-content;
            font-size: 12px;
            border: 1px solid #dc3545;
            padding: 5px;
            border-radius: 5px;
            background-color: #dc3545;
            color: #fff;
            font-weight: bold;
            animation: blink 1s infinite;
          }
        }
      }
    }

  }



  .buttonbordered {
    text-decoration: none;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.modal_lines_container {
  * {
    font-family: "Montserrat", sans-serif;
  }

  .button_cerrar {
    width: fit-content;
    border: 1px solid #3e3e3e;
    font-weight: 600;
    background-color: #bb2d3b;
    color: #e7e4e4;
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      color: #e7e4e4;
      border: 1px solid #3e3e3e;
      box-shadow: 2px 2px 1px #3e3e3e;
    }
  }

  .modal_lines,
  .button_cerrar {
    width: 100%;
  }

  .modal_lines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    .modal_lines_header_radios {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 10px;

      .modal_lines_header_radios_item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        input[type="radio"] {
          width: 20px;
          height: 20px;
        }
      }
    }

    .modal_lines_header {
      .modal_lines_header_select {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        select.select_item {
          width: fit-content;
          border: 1px solid #3e3e3e;
          font-weight: 600;
          padding: 4px 15px;
          border-radius: 6px;
          transition: all 0.3s ease-in-out;
          color: #e7e4e4;
          background-color: #dc3545;

          &:hover {
            cursor: pointer;
            border: 1px solid #3e3e3e;
            box-shadow: 2px 2px 1px #3e3e3e;
          }

          option {
            font-weight: 600;
          }
        }
      }

      input.input_item {
        width: 100%;
        border: 1px solid #bb2d3b;
        font-weight: 600;
        padding: 4px 15px;
        border-radius: 6px;
      }
    }

    .modal_lines_body {
      width: 100%;
      display: flex;
      gap: 10px;

      .modal_lines_body_tipo_de_linea {
        width: 50%;
        position: relative;
        border: 1px solid #3e3e3e;
        padding: 15px 10px;

        .header_action {
          position: absolute;
          top: -12px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
          font-weight: bold;
          background-color: #bb2d3b;
          padding: 0 10px;
          border: 1px solid #3e3e3e;
          z-index: 10;

          span {
            color: #e7e4e4;
          }
        }

        .modal_lines_body_tipo_de_linea_checkboxes {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 10px;

          .modal_lines_body_tipo_de_linea_checkboxes_item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;

            input[type="checkbox"] {
              width: fit-content;
              height: fit-content;
              font-size: 12px;
            }

            white-space: nowrap;

            label {
              width: fit-content;
              height: fit-content;
              font-size: 14px;
              font-weight: bold;
              -webkit-touch-callout: none;
              /* iOS Safari */
              -webkit-user-select: none;
              /* Safari */
              -khtml-user-select: none;
              /* Konqueror HTML */
              -moz-user-select: none;
              /* Old versions of Firefox */
              -ms-user-select: none;
              /* Internet Explorer/Edge */
              user-select: none;
              /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
            }
          }
        }
      }

      .modal_lines_body_periodos {
        width: 50%;
        position: relative;
        border: 1px solid #3e3e3e;
        padding: 15px 10px;

        .header_action {
          position: absolute;
          top: -12px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
          font-weight: bold;
          background-color: #bb2d3b;
          padding: 0 10px;
          border: 1px solid #3e3e3e;
          z-index: 10;
          transition: all 0.3s ease-in-out;

          span {
            color: #e7e4e4;
          }
        }

        .modal_lines_body_periodos_checkboxes {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 10px;

          .modal_lines_body_periodos_checkboxes_item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;

            input[type="checkbox"] {
              width: fit-content;
              height: fit-content;
              font-size: 12px;
            }

            white-space: nowrap;

            label {
              width: fit-content;
              height: fit-content;
              font-size: 14px;
              font-weight: bold;
              -webkit-touch-callout: none;
              /* iOS Safari */
              -webkit-user-select: none;
              /* Safari */
              -khtml-user-select: none;
              /* Konqueror HTML */
              -moz-user-select: none;
              /* Old versions of Firefox */
              -ms-user-select: none;
              /* Internet Explorer/Edge */
              user-select: none;
              /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
            }
          }
        }

      }
    }
  }

  .footer_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #3e3e3e;
    padding: 15px 10px;
    margin-top: 20px;

    button.button_item {
      width: fit-content;
      border: 1px solid #3e3e3e;
      font-weight: 600;
      background-color: #e7e4e4;
      color: #bb2d3b;
      transition: all 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        border: 1px solid #3e3e3e;
        box-shadow: 2px 2px 1px #3e3e3e;
      }
    }

    select.select_item {
      width: fit-content;
      border: 1px solid #3e3e3e;
      font-weight: 600;
      padding: 4px 15px;
      border-radius: 6px;
      background-color: #e7e4e4;
      color: #bb2d3b;
      transition: all 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        border: 1px solid #3e3e3e;
        box-shadow: 2px 2px 1px #3e3e3e;
      }

      option {
        font-weight: 600;
      }
    }

    position: relative;

    .header_action {
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      font-weight: bold;
      background-color: #bb2d3b;
      padding: 0 10px;
      border: 1px solid #3e3e3e;
      z-index: 10;

      span {
        color: #e7e4e4;
      }
    }
  }
}

.actions-modal {
  .ant-modal-content {
    background: none !important;
    width: 400px !important;
    height: 400px !important;
    border-radius: 1000px !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    position: fixed;
    top: 1%;
    left: 50%;
    background-color: #dc3545;

    .ant-modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 400px !important;
      height: 400px !important;
      border-radius: 1000px !important;


      .periods_circle {
        background-color: #e7e4e4;
        width: 400px !important;
        height: 400px !important;
        border-radius: 1000px !important;
        position: relative;
        padding: 10px !important;
      }

      .period_circle_item {
        position: absolute;
        top: 50%;
        left: 50%;
        width: fit-content;
        margin-top: -30px;
        margin-left: -30px;
        line-height: 60px;
        text-align: center;

        .period_circle_item_text {
          font-size: 12px;
          font-weight: bold;
          color: #bb2d3b;

        }
      }


    }
  }

}



.exit-button-hyl {
  background: none;
  border: none;
  color: #CC2F48;
  width: 100%;
  font-size: 1.5rem;
}

#selectStatus {
  div>button {
    &.active {
      background-color: #dc3545 !important;
      color: #e7e4e4 !important;
    }
  }
}

div.no-lines-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  span {
    font-size: 1.3rem;
    font-weight: 700;
  }
}

button#loadLines {
  &.active {
    box-shadow: 0 0 0 0 rgb(255, 0, 0);
    transform: scale(1.1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }

    70% {
      transform: scale(1.1);
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }
}