.reportes-modal {
    .reportes-modal__menu {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #e0e0e0;
        background-color: #f5f5f5;
        gap: 1rem;

        .reportes-modal__menu-item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;
            color: #757575;
            font-size: 1rem;
            font-weight: 500;
            border-bottom: 2px solid #00000000;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: #000;
            }

            &.active {
                color: #000;
                border-bottom: 2px solid #000;
            }
        }

    }


    button {
        width: fit-content !important;
        height: fit-content !important;
        padding: 3px 5px !important;
        margin: 0 !important;
        border-radius: 5px !important;
        background-color: #bdd4f4 !important;
        border: 1px solid #131313 !important;
        color: #131313 !important;
        font-weight: 600;
    }
}

#customers {
    table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        font-size: 1rem !important;
        font-weight: lighter !important;
        margin: 0 !important;
        padding: 0 !important;
        border: 1px solid #131313 !important;
        border-radius: 5px !important;
        background-color: #2d2e42;
        color: #f3f3f3;

        thead {
            background-color: #bdd4f4;
            color: #3e3e3e;
            font-weight: bold !important;
            border: 1px solid #131313 !important;
            border-radius: 5px !important;
        }

        tfoot {
            background-color: #3e3e3e;
            color: #f3f3f3;
            font-weight: bold !important;
            border: 1px solid #131313 !important;
            border-radius: 5px !important;
        }

        tbody {
            color: #3e3e3e;
            font-weight: 700 !important;
            border: 1px solid #131313 !important;
            border-radius: 5px !important;

            tr {
                &:nth-child(even) {
                    background-color: #f2f2f2;
                }

                &:nth-child(odd) {
                    background-color: #ffffff;
                }
            }
        }
    }
}

// responsive mobile:

@media screen and (max-width: 768px) {


    .reportes-modal {
        .reportes-modal__menu {
            overflow-x: auto;
            white-space: nowrap;
            display: flex;
            justify-content: flex-start;
        }
    }

    #customers {

        table {
            width: 100% !important;
            white-space: nowrap;
            overflow: auto;
            font-size: 0.8rem !important;
        }
    }

    .hide_mobile {
        display: none;
    }

    #reportsmodalsimple {
        label {
            font-size: 0.8rem !important;
        }

        input {
            font-size: 0.7rem !important;
        }

        button {
            font-size: 0.7rem !important;
            white-space: nowrap;
        }

        table {
            white-space: nowrap;
            overflow: auto;
            font-size: 0.7rem !important;
        }
    }
}