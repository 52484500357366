/* TableHeader.css */

.casilla-header-limit {
  border-bottom: 3px solid black;
  border-top: 3px solid black;
  border-right: 3px solid black;
  /*border-left: 3px solid black; */
  /* border-radius: 5px ; */
  background-color: #dc3545 !important;
  color: white;
  padding: 10px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1;
  
}

/* .casilla-header-limit:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  border: 3px solid black;
} */