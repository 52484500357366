/*table*/

.container-GR {
    width: 80%;
    height: 70%;
    background-color: white;
    margin: auto;
    border: solid 3px slategray;
    border-radius: 10px;
}
.table-container {
    width: 100%;
    border: solid 3px slategray;
    border-radius: 10px;
    table-layout: fixed;
    border-collapse: separate
}
