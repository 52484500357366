:root {
    --background-color: #fff;
    --text-color: #6d6d6d;
    --text-color-secondary: #163575;
    --buttons-gradient: linear-gradient(180deg, #DCEFFF 36%, #CAE3FF 37%, #CAE3FF 100%);
    --buttons-gradient-disabled: #d6e5fc;
}

#LayoutClient4 {
    background-color: var(--background-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--text-color);
    font-family: "Roboto", sans-serif;
    gap: 10px;

    .bg-card {
        background-color: #f9f9f9 !important;
        // border-radius: 5px;
        // border: solid 1px #0d2d6a;
        padding: 5px;
        font-size: 14px !important;

        .ticket-top {
            border: none;
        }
    }


    .navbar-brand.active {
        border: 2px solid #FBAE72;
    }

    .footer_container {
        border: 1px solid var(--text-color-secondary);
        height: 60px;
    }

    .footer_button {
        background: var(--buttons-gradient);
        border-radius: 10px;
        color: var(--text-color-secondary);
        border: 1px solid var(--text-color-secondary);
    }

    .footer_button:disabled {
        background: var(--buttons-gradient-disabled);
    }

    .button_to_add_bet button {
        position: absolute;
        top: 50%;
        left: 50%;
        background: var(--buttons-gradient) !important;
        border-radius: 10px;
    }

    #left-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        select.betType {
            border: none;
            font-weight: bold;
            text-align: center;
            text-align-last: center;
        }
    }

    #right-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        position: relative;

        * {
            // font-size: 14px !important;
            font-weight: 700;
        }

        .right_button {
            background: var(--buttons-gradient);
            border-radius: 10px;
            color: var(--text-color-secondary);
            border: 1px solid var(--text-color-secondary);
            gap: 3px;
        }

        .right_button a {
            width: 100% !important;

        }

        .Tables_right_container {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            height: 55vh;
            overflow: auto;
            gap: 10px;
        }

        .table-responsive {
            overflow-y: scroll;

            ::-webkit-scrollbar {
                width: 15px !important;
                height: 15px !important;
            }

            ::-webkit-scrollbar-track {
                background: #f1f1f1 !important;
            }

            ::-webkit-scrollbar-thumb {
                background: #0d2d6a !important;
            }
        }

        .Tables_right_container::-webkit-scrollbar {
            width: 15px;
            height: 15px;
        }

        .Tables_right_container::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        .Tables_right_container::-webkit-scrollbar-thumb {
            background: #0d2d6a;
        }



        .Tables_right_container .table-responsive table {
            width: 100%;
            border: 1px solid #0d2d6a;
            color: #000;

            thead {
                th {
                    padding: 0.5rem !important;
                    font-size: 11px !important;
                    font-weight: 800 !important;
                    text-transform: uppercase !important;
                    background-color: #0d2e6abe !important;
                    color: #f1f1f1;
                }
            }

            tbody {
                td {
                    padding: 0.5rem !important;
                    font-size: 11px !important;
                    font-weight: 800 !important;
                    text-transform: uppercase !important;
                    background-color: #fff !important;

                    * {
                        width: fit-content !important;
                    }
                }
            }
        }

    }

    header.header_container {
        width: 100%;
        display: flex;
        position: sticky;
        top: 0;
        z-index: 99;
        background-color: #202020;

        // button.footer_button {
        //     width: 100%;
        //     border-radius: 0;
        //     border: none;
        //     background: #013368 !important;
        //     color: #f1f1f1;
        //     text-transform: uppercase;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     gap: 4px;
        // }
        .menu-wrap {
            width: fit-content;
        }

        .menu,
        .drop-menu {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        .menu-item {
            display: inline-block;
            padding: 0 10px;
            background-color: #013368;
            width: 100%;
            padding: 0 10px;
            position: relative;
            white-space: nowrap;
            text-transform: uppercase;
        }

        button {
            background-color: #013368;
            border: none;
            color: #fff;
            width: fit-content;
            padding: 0 10px;
        }

        .menu-item a {
            text-decoration: none;
            padding: 6px 10px;
            color: #fff;
            display: flex;
            gap: 5px;
            align-items: center;
            justify-content: center;
            font-size: 15px;

            i {
                font-size: 12px;
            }
        }

        .drop-menu {
            display: none;
            position: absolute;
            background-color: #fff;
            min-width: 100px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
        }

        .drop-menu-item {
            width: 100%;
        }

        .drop-menu-item:hover {
            background-color: #eee;
        }

        .drop-menu-item a {
            color: #555;
        }

        .menu-item:hover .drop-menu {
            display: block;
        }
    }

    div.layoutbotton_container {
        display: flex;
        width: 100%;
        border: 5px solid #CC0001;
        height: calc(100vh - 45px);
        overflow: hidden;
        -webkit-border-radius: 5px 5px 5px 5px;
        border-radius: 5px;

        // menus styles:
        .menu_container {
            background-color: #f1f1f1;
            width: 100%;
            height: calc(100% - 65px);
            overflow: auto;

            button {
                min-width: 180px;
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 5px;
                background-color: #397cb3;
                color: #fff;
                font-size: 12px;
                font-weight: 800;
                text-transform: uppercase;
                text-align: center;
            }

            .top_container {
                width: 100%;

                .balance_container {
                    background-color: #25d366;
                    width: fit-content;
                    height: fit-content;
                    margin: 0 auto;
                    padding: 5px 8px;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 800;
                    margin-bottom: 20px;
                }

                >div:not(.balance_container) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;
                    padding: 20px 0;



                    >span {
                        font-size: 15px;
                        font-weight: 800;
                        text-transform: uppercase;
                        color: #333;
                    }

                    label {
                        font-size: 12px;
                        font-weight: 500;
                        color: #333;
                    }

                    input {
                        padding: 5px;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        min-width: 250px;
                        text-align: center;
                    }



                    div.date_container {
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                        justify-content: center;
                        align-items: center;
                    }

                    div._2_inputs {
                        display: flex !important;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        input {
                            min-width: 100px;
                        }
                    }

                    div._3_inputs {
                        display: flex !important;
                        flex-direction: row !important;
                        justify-content: center !important;
                        align-items: center !important;
                        width: 100%;
                        gap: 5px;

                        input {
                            width: 100px !important;
                            min-width: 100px !important;
                        }

                        select {
                            padding: 5px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            width: 100px;
                            min-width: 100px;
                            text-align: center;
                        }
                    }


                }
            }

            .bottom_container {
                width: 100%;
                border-top: 1px solid #ccc;
                padding: 20px 0;
                display: flex;


                .tables_container {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;

                    table {
                        width: 100%;
                        border-collapse: collapse;
                        border-top: 1px solid #ccc;
                        border-bottom: 1px solid #ccc;

                        thead {
                            background-color: #232223;
                            text-align: center;

                            th {
                                padding: 5px;
                                color: #f3f3f3;
                                font-size: 16px;
                                font-weight: 500;
                                text-transform: uppercase;

                                div {
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    padding: 0 10px;
                                    align-items: center;

                                    span {
                                        width: 100%;
                                    }
                                }
                            }

                            td {
                                background-color: #6b6b6b;
                                color: #fff;
                            }
                        }

                        tbody {
                            tr {
                                &:nth-child(even) {
                                    background-color: #f1f1f1;
                                }

                                &:nth-child(odd) {
                                    background-color: #fff;
                                }

                                td {
                                    padding: 5px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #333;

                                    &:first-child {
                                        border-right: 1px solid #ccc;
                                    }
                                }
                            }
                        }
                    }
                }

                button {
                    border: none;
                }
            }

            .line-home-container__leagues {
                display: flex;
                justify-content: center;
                align-items: center;

                :first-child {
                    border-radius: 5px 0 0 5px;
                }

                :last-child {
                    border-radius: 0 5px 5px 0;
                }

                >div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #2e6da4;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 2px 8px;
                    margin: 20px 0;


                    &.active {
                        background-color: #25d366;
                    }
                }
            }

            .line-home-container__table {
                >div {
                    table {
                        thead {
                            * {
                                color: #f1f1f1;
                            }

                            tr:first-child {
                                background-color: #232223;
                            }

                            tr:last-child {
                                background-color: #6b6b6b;
                            }
                        }

                        tbody {
                            tr {
                                &:nth-child(even) {
                                    background-color: #f1f1f1;
                                }

                                &:nth-child(odd) {
                                    background-color: #fff;
                                }

                                td {
                                    font-weight: 500;
                                    color: #333;

                                    &:nth-child(2) {
                                        padding: 0 3px;
                                    }

                                    &:nth-child(odd) {
                                        border-right: 1px solid #ccc;
                                    }

                                    &:nth-child(even) {
                                        border-right: 1px solid #ccc;
                                    }

                                    &:last-child {
                                        border: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .sub_menu-container {
                width: 100%;

                >button {
                    margin: 10px;
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    background-color: #2E2E2E;
                    box-shadow: 0 0 5px #333;
                    border: none;
                }

                .container_sub_menu {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 100%;
                    padding: 10px;

                    >div:not(.bottom_container, .keyboard) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        label {
                            font-size: 14px;
                            font-weight: 500;
                            color: #333;
                            margin-bottom: 5px;
                        }

                        select {
                            padding: 5px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            width: 100px;
                            min-width: 100px;
                            text-align: center;
                        }
                    }

                    .bottom_container {
                        width: 100%;
                        padding: 20px 0;
                        display: flex;

                        >div {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            label {
                                font-size: 14px;
                                font-weight: 500;
                                color: #333;
                                margin-bottom: 5px;
                            }

                            input {
                                padding: 5px;
                                border: 1px solid #ccc;
                                border-radius: 5px;
                                width: 100px;
                                min-width: 100px;
                                text-align: center;
                            }

                            select {
                                padding: 5px;
                                border: 1px solid #ccc;
                                border-radius: 5px;
                                width: 100px;
                                min-width: 100px;
                                text-align: center;
                            }
                        }
                    }

                    .keyboard {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
                        gap: 5px;
                        width: 80vw;
                        margin: 0 auto;


                        button.btn-keyboard {
                            width: 90px;
                            min-width: 90px;
                            height: 60px;
                            padding: 5px;
                            border: none;
                            border-radius: 5px;
                            background-color: #c4c4c4;
                            box-shadow: inset 0 0 5px rgba(54, 54, 54, 0.31);
                            color: #333;
                            font-size: 14px;
                            margin: 0;

                            &.btn-keyboard-ok {
                                background-color: #25d366;
                                color: #fff;
                            }

                            &.btn-keyboard-back {
                                background-color: #615b5b;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    div.righ_container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .Tables_right_container {
            width: 100%;
            height: 100%;

            div.table-responsive {
                height: 100%;
                width: 100%;
            }
        }

        header.header {
            width: 100%;
            border-bottom: 5px solid #CC0001;
            background-color: #777;
            color: #fff;
            min-height: 37px;

            .leagues-nav {
                display: flex;
                align-items: center;

                a {
                    text-align: left;
                    float: left;
                    width: 110px;
                    padding: 2.5px 5px;
                    background: #444;
                    font-family: 'Roboto Condensed', sans-serif;
                    color: white;
                    font-size: 12px;
                    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                    -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                    text-decoration: none;
                    height: 32px;
                    display: flex;
                    align-items: center;

                    &.active {
                        background: #CC0001;
                    }

                    img {
                        width: 28px;
                        height: auto;
                        margin-right: 5px;
                        vertical-align: middle;
                        object-fit: contain;
                    }

                    span {
                        font-size: 12px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .league_selected {
            background: #333;
            color: white;
            font-size: 12px;
            font-family: Arial;
            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            padding: 3px 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            min-height: 31px;

            img {
                width: 25px;
                height: auto;
                max-height: 25px;
                object-fit: contain;
            }
        }

        .table-responsive {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .lines-games-vertical__table__period__title {
                width: 100%;
                background: #333;
                color: white;
                font-size: 12px;
                font-family: Arial;
                -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                padding: 3px 5px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
                min-height: 31px;
            }

            table {
                border: none;

                thead {

                    tr {
                        background: #666;
                        color: white;
                        font-size: 11px;
                        font-family: Arial;
                        -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        text-transform: uppercase;
                        border-left: 1px solid #000;
                        border-right: 1px solid #000 !important;
                    }
                }
            }
        }
    }

    div.left_container {
        display: flex;
        flex-direction: column;
        border-right: 5px solid #CC0001;


        .left_icons {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            float: left;
            width: 100%;
            height: 35px;
            background-color: #333;
            border-bottom: 1px solid #777;
            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

            button {
                background-color: transparent;
                border: none;
                color: #fff;
                width: fit-content;
                padding: 0 10px;

                i {
                    font-size: 19px;
                }
            }

            .tooltip {
                position: relative;
                display: inline-block;
                border-bottom: 1px dotted black;
            }

            .tooltip .tiptext {
                visibility: hidden;
                width: 120px;
                background-color: black;
                color: #fff;
                text-align: center;
                border-radius: 3px;
                padding: 6px 0;
                position: absolute;
                z-index: 1;
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            }

            .tooltip .tiptext::after {
                content: "";
                position: absolute;
                border-width: 5px;
                border-style: solid;
            }

            .tooltip:hover .tiptext {
                visibility: visible;
            }

            .tooltip.top .tiptext {
                margin-left: -60px;
                bottom: 150%;
                left: 50%;
            }

            .tooltip.top .tiptext::after {
                margin-left: -5px;
                top: 100%;
                left: 50%;
                border-color: #2E2E2E transparent transparent transparent;
            }
        }

        .left_inputs {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 35px;
            background-color: #333;
            border-bottom: 1px solid #777;
            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            padding: 5px;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                input {
                    width: 100px;
                    border: none;
                    color: #202020;
                    text-align: center;
                    font-size: 15px;
                    font-weight: bold;
                    border-radius: 7px;
                    height: 30px;
                    font-weight: bold;
                    font-size: 12px;
                    text-align: center;
                    text-transform: uppercase;
                    display: block;
                    padding: 0px;
                    line-height: 1.42857143;
                    color: #555;
                    background-color: #fff;
                    background-image: none;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                }

                button.QB {
                    font-size: 18px;
                    width: 45px;
                    height: 30px;
                    color: red;
                    font-weight: bold;
                    text-shadow: 0px 0px 1px white;
                    background-color: #f0ad4e;
                    border-radius: 3px;
                    padding: 1px 5px;
                    border-color: #eea236;
                }
            }
        }

        .left_actions {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;
            background-color: #333;
            border-bottom: 1px solid #777;
            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            padding: 5px;

            input {
                display: block;
                width: 100%;
                height: 34px;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                background-image: none;
                border: 1px solid #ccc;
                border-radius: 4px;
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
                -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                height: 30px;
                font-weight: bold;
                font-size: 12px;
                text-align: center;
                text-transform: uppercase;
                margin: 0 auto;

            }

            .left_actions_buttons {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 35px;
                width: 100%;

                div {
                    height: 30px;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                    font-size: 14px;
                    line-height: 1.42857143;
                    color: #fff;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &>input[type=checkbox] {
                        display: none;
                    }

                    &>input[type=checkbox]+label {
                        font-family: Helvetica, Arial, sans-serif;
                        color: White;
                        cursor: pointer;
                        width: 91px;
                        margin-right: auto;
                        border: 1px solid #777;
                        text-align: center;
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 2px;
                        -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                    }

                    label {
                        display: inline-block;
                        max-width: 100%;
                        font-weight: 700;
                    }

                    &.send_whatsapp>input[type=checkbox]:checked+label {
                        background: #25d366;
                        text-shadow: 0px 0px 2px #000;
                    }

                    &.send_email>input[type=checkbox]:checked+label {
                        background: #2E9AFE;
                        text-shadow: 0px 0px 2px #000;
                    }

                    &.print>input[type=checkbox]:checked+label {
                        background: #2E9AFE;
                        text-shadow: 0px 0px 2px #000;
                    }
                }
            }

        }

        .typeOfBet {
            float: left;
            width: 100%;
            height: 30px;
            background-color: #333;

            &.typeOfBet>input[type=radio] {
                display: none;
            }

            &.typeOfBet>input[type=radio]:checked+label {
                background: #EC6000;
                text-shadow: 0px 0px 1px #000;
            }

            label {
                display: inline-block;
                max-width: 100%;
                margin-bottom: 5px;
                font-weight: 700;
            }

            &.typeOfBet>input[type=radio]+label {
                font-family: Helvetica, Arial, sans-serif;
                color: White;
                cursor: pointer;
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                border-right: 1px solid #777;
                border-top: 1px solid #777;
                border-bottom: 1px solid #777;
                text-align: center;
                font-size: 10px;
                padding-top: 7px;
                -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                height: 30px;
            }

        }

        .footer_left_container {
            float: left;
            // padding-top: 5px;
            height: 95px;
            background: #333;
            width: 100%;
            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
            padding: 7px 5px;
            display: flex;
            flex-direction: column;
            gap: 5px;


            .bet {
                float: left;
                height: 35px;
                background: #ccc;
                width: 100%;
                max-width: 290px;
                margin: 0 auto;
                // margin-left: 7px;
                // margin-top: 5px;
                // padding-top: 5px;
                border-radius: 3px;
                -moz-border-radius: 5px 5px 5px 5px;
                -webkit-border-radius: 5px 5px 5px 5px;
                border: 0px solid #000000;
                padding: 0 5px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                gap: 5px;

                input[disabled] {
                    cursor: not-allowed;
                }

                input[disabled],
                input[readonly],
                fieldset[disabled] input {
                    background-color: #eee;
                    opacity: 1;
                }

                input {
                    border-radius: 5px;
                    border: none;

                    &:focus {
                        outline: none;
                        box-shadow: 0px 0px 0px 2px #096df049;
                    }

                    &#nro_jugadasbet,
                    &#nro_jugadasif {
                        height: 25px;
                        font-weight: bold;
                        font-size: 12px;
                        text-align: center;
                        width: 25px;
                        display: inline;
                        padding: 0;
                    }

                    &#quantityInput,
                    &#ifWinInput {
                        height: 25px;
                        font-weight: bold;
                        font-size: 12px;
                        text-align: center;
                        width: 100%;
                        display: inline;
                        margin-left: 0px;
                    }

                    &#t_aganar,
                    &#t_aganar_if {
                        height: 25px;
                        font-weight: bold;
                        font-size: 12px;
                        text-align: center;
                        width: 100%;
                        display: inline;
                        margin-left: 0px;
                    }

                    &#monto_winbet,
                    &#monto_winif {
                        height: 25px;
                        font-weight: bold;
                        font-size: 12px;
                        text-align: center;
                        width: 100%;
                        display: inline;
                        margin-left: 0px;
                    }
                }
            }
        }

        .bets_container {
            height: 100%;
            width: 100%;

            #table_bets_render_container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                width: 100%;
                background-color: #f1f2f3;

                table {
                    opacity: 0.95;
                    float: left;
                    letter-spacing: 0px;
                    text-align: center;
                    background-color: #333;
                    color: white;
                    font-family: arial;
                    text-transform: uppercase;

                    thead {
                        display: table-header-group;
                        vertical-align: middle;
                        border-color: inherit;

                        .table_title {
                            color: white;
                            background-color: #333;
                            font-family: 'Arial', sans-serif;
                            font-weight: normal;
                            font-size: 11px;
                            border-top: 1px solid #555;
                            border-bottom: 1px solid #555;
                            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                            -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        }

                        th {
                            &:nth-child(even) {
                                border-right: 1px solid #555;
                            }

                            &:nth-child(odd) {
                                border-left: 1px solid #555;
                            }

                            &:nth-child(1) {
                                border-left: none;
                                border-right: 1px solid #555;
                            }
                        }
                    }

                    tbody {
                        background-color: #fff;
                        display: table-row-group;

                        tr {
                            background: #013368;
                            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                            -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                            box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                            font-size: 10px;
                            border-bottom: 1px solid #777;
                            color: #fff;

                            td {
                                padding: 3px 0px !important;

                                // &:nth-child(even) {
                                //     border-right: 1px solid #555;
                                // }

                                // &:nth-child(odd) {
                                //     border-left: 1px solid #555;
                                // }

                                // &:nth-child(1) {
                                //     border-left: none;
                                //     border-right: 1px solid #555;
                                // }
                            }
                        }
                    }
                }

                button.QB {
                    font-size: 18px;
                    width: 45px;
                    height: 30px;
                    color: red;
                    font-weight: bold;
                    text-shadow: 0px 0px 1px white;
                    background-color: #f0ad4e;
                    border-radius: 3px;
                    padding: 1px 5px;
                    border-color: #eea236;
                }
            }
        }
    }

    .delete_bet_button {
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        color: #fff;
        background-color: #d9534f;
        border: 1px solid #d43f3a;
        // border-color: #d43f3a;
    }
}

// ****************** ~ ****************** //

.bet-selected {
    background: linear-gradient(180deg, #ffdbb5 0%, #ffb65a 42%, #ffe794 85%, #fff 100%);
}

.border-bottom-ticket:before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: -6px;
    border-radius: 0 18px 18px 0;
    border-top: solid 1px #0d2d6a;
    border-right: solid 1px #0d2d6a;
    border-bottom: solid 1px #0d2d6a;
    width: 16px;
    height: 30px;
    background-color: #bdd4f4;
    /* background-color: black; */
}

.border-bottom-ticket:after {
    content: '';
    position: absolute;
    bottom: -15px;
    right: -7px;
    border-radius: 18px 0 0 18px;
    border-top: solid 1px #0d2d6a;
    border-left: solid 1px #0d2d6a;
    border-bottom: solid 1px #0d2d6a;
    width: 18px;
    height: 30px;
    background-color: #bdd4f4;
}



.ticket-top {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 3px dashed #eeeff3;
}

.ticket-top {
    height: 60% !important;
    padding-top: 15px;
}

.ticket-bottom {
    height: 40% !important;
}

.vertical__table__period__title {
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    color: #202020 !important;
}

div.ant-modal.modal-dinamic-t4 {
    width: 100vw !important;
    transform: scale(1) !important;

    @media screen and (max-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 1120px) {
        display: block;
    }

    &.modal-dinamic-t4-new-account {
        width: 100vw !important;
        max-width: 600px !important;
        transform: scale(1) !important;
    }


    .ant-modal-header {
        div {
            text-align: center;
            font-size: 25px;
            text-transform: uppercase;
        }
    }

    .ant-modal-body {


        padding: 10px;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #333;
        }

        hr {
            margin-top: 20px;
            margin-bottom: 20px;
            border: 0;
            border-top: 1px solid #a8a8a8;
            z-index: -1;
        }

        .sales-report-date {
            width: 100%;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #e5e5e5;
            border-top: 1px solid #e5e5e5;
            padding: 25px 10px 10px 10px;
            position: sticky;
            top: 0;
            background-color: #fff;

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;

                input {
                    width: 100%;
                    height: 30px;
                    border-radius: 3px 0 0 3px;
                    position: relative;
                    z-index: 2;
                    float: left;
                    text-align: center;
                    margin-bottom: 0;
                    height: 30px;
                    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                    background-color: #fff;
                    padding: 5px 10px;
                    font-size: 12px;
                    line-height: 1.5;
                    border: 1px solid #ccc;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                    font-family: inherit;
                    writing-mode: horizontal-tb !important;
                }

                span {
                    width: fit-content;
                    min-width: 46px;
                    padding: 4px 5px;
                    line-height: 1.42857143;
                    text-shadow: 0 1px 0 #fff;
                    border-width: 1px 0;
                    margin-left: -5px;
                    margin-right: -5px;

                    font-size: 14px;
                    font-weight: 400;
                    color: #555;
                    text-align: center;
                    background-color: #eee;
                    border: 1px solid #ccc;

                    white-space: nowrap;
                    vertical-align: middle;
                }
            }

            button {
                display: inline-block;
                padding: 6px 12px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.42857143;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                -ms-touch-action: manipulation;
                touch-action: manipulation;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background-image: none;
                border: 1px solid transparent;
                border-radius: 4px;
                color: #fff;
                background-color: #337ab7;
                border-color: #2e6da4;
                width: fit-content;
                margin: 10px auto;
            }

        }

        &>div {
            width: 100%;

            h3 {
                font-size: 24px;
                text-transform: uppercase;
                color: #202020;
                margin: 10px 0;
                text-align: center;
            }

            select {
                display: block;
                width: 100%;
                height: 34px;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                background-image: none;
                border: 1px solid #ccc;
                border-radius: 4px;
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
                -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                width: 300px;
                // margin-left: 10px;
                // margin-bottom: 5px;
            }

            label {
                font-size: 14px;
                font-weight: 600;
                color: #000;
                margin-bottom: 5px;
                width: 100px;
            }

            input {
                :focus {
                    outline: none !important;
                }

                // is text
                &:not([type="checkbox"]):not([type="radio"]:not([type="password"])) {
                    width: 100%;
                    height: 30px;
                    border-radius: 3px 0 0 3px;
                    position: relative;
                    z-index: 2;
                    float: left;
                    text-align: center;
                    margin-bottom: 0;
                    height: 30px;
                    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                    background-color: #fff;
                    padding: 5px 10px;
                    font-size: 14px;
                    line-height: 1.5;
                    border: 1px solid #ccc;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                    font-family: inherit;
                    writing-mode: horizontal-tb !important;
                }

                // is checkbox
                &:not([type="radio"]):not([type="checkbox"]) {}

                // is radio
                &:not([type="checkbox"]):not([type="radio"]) {}

                &.password-error {
                    border: 1px solid #CC0001 !important;
                    color: #CC0001 !important;
                    font-size: 15px !important;
                    font-weight: 600 !important;
                }
            }

            label.show-password {
                height: 100%;
                width: 30px;
                background-color: #fff;
                border-radius: 0 3px 3px 0;
                position: relative;
                z-index: 1;
                float: left;
                text-align: center;
                margin-bottom: 0;
                height: 30px;
                transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                background-color: #f4f4f4;
                padding: 5px 10px;
                font-size: 14px;
                line-height: 1.5;
                border: 1px solid #ccc;
                border-left: none;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                font-family: inherit;
                writing-mode: horizontal-tb !important;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 14px;
                    color: #555;
                }

                // input checkbox hidden
                input {
                    &:not([type="text"]):not([type="radio"]) {
                        display: none;
                    }
                }
            }
        }

        div.sales-report {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            div.balance {
                background-color: #5cb85c;
                font-size: 14px;
                text-shadow: 0px 0px 2px black;
                display: inline;
                padding: .2em .6em .3em;
                font-weight: 700;
                line-height: 1;
                color: #fff;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                border-radius: 4px;
                margin: 10px auto;
            }

            .sales-report-inputs {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                label {
                    font-size: 14px;
                    color: #202020;
                }

                input {
                    display: block;
                    width: 100%;
                    height: 34px;
                    padding: 6px 12px;
                    font-size: 14px;
                    line-height: 1.42857143;
                    color: #555;
                    background-color: #fff;
                    background-image: none;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
                    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                    text-align: center;
                    min-width: 400px;
                }


            }

            .sales-report-radio {
                display: flex;
                gap: 5px;
                font-size: 15px;
            }

            button.send {
                color: #fff;
                background-color: #5bc0de;
                border-color: #46b8da;
                height: 35px;
                margin-top: 10px;
                display: inline-block;
                padding: 6px 12px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.42857143;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                -ms-touch-action: manipulation;
                touch-action: manipulation;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background-image: none;
                border: 1px solid transparent;
                border-radius: 4px;
            }
        }

        div.sales-report-tables {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            &>div {
                width: 100%;

                table {
                    width: 100%;
                    border-collapse: collapse;
                    border-spacing: 0;
                    border: 1px solid #bbbbbb;
                    margin-bottom: 20px;
                    color: white;
                    text-transform: uppercase;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

                    thead {
                        // background-color: #333;
                        // border-bottom: 1px solid #ddd;

                        tr {
                            background: #333;
                            text-align: center;
                            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                            -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                            /* box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75); */
                            font-size: 12px;
                            height: 30px;

                            th {
                                border: 1px solid #bbbbbb;
                                padding: 5px;
                                font-size: 12px;
                                font-weight: 400;
                                text-align: center;
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                border: 1px solid #bbbbbb;
                                padding: 5px;
                                font-size: 12px;
                                font-weight: 400;
                                text-align: center;
                                color: #333;
                            }
                        }
                    }

                    tfoot {
                        tr {
                            background: #333;
                            text-align: center;
                            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                            -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                            /* box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75); */
                            font-size: 12px;
                            height: 30px;

                            td {
                                border: 1px solid #bbbbbb;
                                padding: 5px;
                                font-size: 12px;
                                font-weight: 400;
                                text-align: center;
                                color: #fff;
                            }
                        }
                    }
                }
            }

        }

        .createaccount {
            input {
                max-width: 300px;
            }
        }

        .sales-report-table {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;

            >table {
                width: calc(100% - 360px);
                border-collapse: collapse;
                border-spacing: 0;
                border: 1px solid #bbbbbb;
                color: white;
                text-transform: uppercase;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

                thead {
                    // background-color: #333;
                    // border-bottom: 1px solid #ddd;
                    display: table;
                    table-layout: fixed;
                    width: calc(100%);

                    /* even columns width , fix width of table too*/
                    tr {
                        background: #333;
                        text-align: center;
                        -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        /* box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75); */
                        font-size: 12px;
                        height: 30px;

                        th {
                            border: 1px solid #bbbbbb;
                            padding: 5px;
                            font-size: 12px;
                            font-weight: 400;
                            text-align: center;
                        }
                    }
                }

                tbody {
                    display: block;
                    height: 300px;
                    width: calc(100%);
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 10px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #333;
                    }

                    tr {
                        display: table;
                        width: calc(100%);
                        table-layout: fixed;
                        /* even columns width , fix width of table too*/

                        td {
                            width: 100px;
                            overflow: hidden;
                            border: 1px solid #bbbbbb;
                            // padding: 5px;
                            font-size: 12px;
                            font-weight: 400;
                            text-align: center;
                            color: #333;

                            div.ticket-status {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                gap: 5px;

                                &.cancelled {
                                    color: #202020;
                                    background-color: #F5D0A9;
                                }

                                &.loser {
                                    color: #202020;
                                    background-color: #f5a9a9;
                                }

                                &.winner {
                                    color: #202020;
                                    background-color: #A9F5A9;
                                }

                                &.pending {
                                    color: #202020;
                                    background-color: #F3F781;
                                }
                            }
                        }

                        &.selected {
                            background-color: #646363;

                            * {
                                color: white;
                            }
                        }
                    }
                }

                tfoot {
                    display: table;
                    table-layout: fixed;
                    width: calc(100%);

                    tr {
                        background: #333;
                        text-align: center;
                        -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                        /* box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75); */
                        font-size: 12px;
                        height: 30px;

                        td {
                            border: 1px solid #bbbbbb;
                            padding: 5px;
                            font-size: 12px;
                            font-weight: 400;
                            text-align: center;
                            color: #fff;
                        }
                    }
                }
            }

            &>div {
                width: 360px;
                height: 275px;

                >div {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    position: sticky;
                    top: 20px;

                    button {
                        width: fit-content;
                        height: fit-content;
                        padding: 3px 10px;
                        margin: 10px 5px;
                        color: #fff;
                        background-color: #5bc0de;
                        border: #46b8da 1px solid;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &.selected-ticket {
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        border: 1px solid #333;
                        height: calc(100% - 40px);


                        i {
                            margin: 0;
                            margin-bottom: 10px;
                        }

                        span {
                            font-size: 22px;
                            font-weight: 600;
                            color: #333;
                            line-height: 1.2;
                        }

                        table {
                            width: 100%;
                            border-collapse: collapse;
                            border-spacing: 0;
                            border: none;
                            color: white;
                            text-transform: uppercase;
                            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

                            thead {
                                // background-color: #333;
                                // border-bottom: 1px solid #ddd;
                                display: table;
                                table-layout: fixed;
                                width: calc(100%);

                                /* even columns width , fix width of table too*/
                                tr {
                                    background: #333;
                                    text-align: center;
                                    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                                    -moz-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
                                    /* box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75); */
                                    height: 30px;

                                    th {
                                        border: none;
                                        padding: 5px;
                                        font-weight: 600;
                                        text-align: center;
                                        font-size: 8px;
                                    }
                                }
                            }

                            tbody {
                                display: block;
                                height: 120px;
                                overflow-y: auto;


                                &::-webkit-scrollbar {
                                    width: 10px;
                                }

                                &::-webkit-scrollbar-track {
                                    background: #f1f1f1;
                                }

                                &::-webkit-scrollbar-thumb {
                                    background: #333;
                                }

                                tr {
                                    display: table;
                                    width: 100%;
                                    table-layout: fixed;
                                    /* even columns width , fix width of table too*/

                                    td {
                                        border: 1px solid #bbbbbb;
                                        padding: 5px;
                                        font-size: 8px;
                                        font-weight: 600;
                                        text-align: center;
                                        color: #333;

                                        div {

                                            &.winner {
                                                background-color: #85B200;
                                            }

                                            &.loser {
                                                background-color: #F5D0A9;
                                            }

                                            &.pending {
                                                background-color: #F5D0A9;
                                            }

                                            &.cancelled {
                                                background-color: #bbbbbb;
                                            }


                                        }
                                    }

                                    &.selected {
                                        background-color: #646363;

                                        * {
                                            color: white;
                                        }
                                    }
                                }
                            }


                        }
                    }
                }
            }
        }

        .sales-report-loading {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            background: rgba(0, 0, 0, .4);
            backdrop-filter: blur(1px);
            -webkit-box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.75);
            -moz-box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.75);
            border-radius: 10px;
            /* box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75); */
            z-index: 9999;
        }


        &.loading {

            .sales-report-loading {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }


        .container {
            width: 200px;
            height: 200px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: auto;
            animation: rotate-move 2s ease-in-out infinite;
        }

        .dot {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: #000;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        .dot-3 {
            background-color: #f74d75;
            animation: dot-3-move 2s ease infinite, index 6s ease infinite;
        }

        .dot-2 {
            background-color: #10beae;
            animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite;
        }

        .dot-1 {
            background-color: #ffe386;
            animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite;
        }

        @keyframes dot-3-move {
            20% {
                transform: scale(1)
            }

            45% {
                transform: translateY(-18px) scale(.45)
            }

            60% {
                transform: translateY(-90px) scale(.45)
            }

            80% {
                transform: translateY(-90px) scale(.45)
            }

            100% {
                transform: translateY(0px) scale(1)
            }
        }

        @keyframes dot-2-move {
            20% {
                transform: scale(1)
            }

            45% {
                transform: translate(-16px, 12px) scale(.45)
            }

            60% {
                transform: translate(-80px, 60px) scale(.45)
            }

            80% {
                transform: translate(-80px, 60px) scale(.45)
            }

            100% {
                transform: translateY(0px) scale(1)
            }
        }

        @keyframes dot-1-move {
            20% {
                transform: scale(1)
            }

            45% {
                transform: translate(16px, 12px) scale(.45)
            }

            60% {
                transform: translate(80px, 60px) scale(.45)
            }

            80% {
                transform: translate(80px, 60px) scale(.45)
            }

            100% {
                transform: translateY(0px) scale(1)
            }
        }

        @keyframes rotate-move {
            55% {
                transform: translate(-50%, -50%) rotate(0deg)
            }

            80% {
                transform: translate(-50%, -50%) rotate(360deg)
            }

            100% {
                transform: translate(-50%, -50%) rotate(360deg)
            }
        }

        @keyframes index {

            0%,
            100% {
                z-index: 3
            }

            33.3% {
                z-index: 2
            }

            66.6% {
                z-index: 1
            }
        }
    }

    .sales-report-search {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        label {
            font-size: 15px !important;
            font-weight: 500;
            width: 100%;
            color: #333;
        }

        input {
            width: 300px !important;
        }
    }

    .ant-modal-footer {
        .btn-footer {
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.42857143;
            margin-bottom: 0;
            padding: 6px 12px;
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;
            border: 1px solid transparent;
            border-radius: 4px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-left: 10px;

            &.btn-success {
                color: #fff;
                background-color: #5cb85c;
                border-color: #4cae4c;

                i {
                    margin-right: 5px;
                }
            }

            &.btn-danger {
                color: #fff;
                background-color: #4f7fd9 !important;
                border-color: transparent;

                i {
                    margin-right: 5px;
                }
            }
        }
    }

    .alert {
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .alert-warning {
        color: #8a6d3b;
        background-color: #fcf8e3;
        border-color: #faebcc;
    }

}

@media (max-width: 768px) {

    .righ_container,
    .left_container {
        display: none !important;
    }

    #LayoutClient4 {
        width: 100vw;
        overflow-x: hidden;
        z-index: 0;
        background-color: #232223;
        box-shadow: inset 0px 0px 120px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: inset 0px 0px 120px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: inset 0px 0px 120px 0px rgba(0, 0, 0, 0.25);
        grid-gap: 0px;
        gap: 0px;

        header.header_mobile {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 7px 10px;
            border-bottom: 1px solid #000;
            z-index: 101;
            background-color: #232223;
            box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

            img {
                width: 100px;
                height: 100%;
            }

            .header_mobile_right {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                .mobile_opener_t4 {
                    background-color: #232223;
                    width: 50px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 2px;
                    border: 1px solid #555555;
                    padding: 5px 5px;

                    span {
                        width: 100%;
                        height: 100%;

                        svg {
                            fill: #fff;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                svg.changetheme {
                    width: 30px;
                    height: 30px;
                    fill: #fff;
                    cursor: pointer;
                }
            }
        }

        nav.menu_container {
            position: absolute;
            top: -100%;
            left: 0;
            width: 100%;
            height: fit-content;
            border-bottom: 1px solid #000;
            // shadow only on top inset white
            box-shadow: inset 0px 0px 120px 0px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: inset 0px 0px 120px 0px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: inset 0px 0px 120px 0px rgba(0, 0, 0, 0.25);
            background-color: #232223;
            z-index: 100;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 14px;
            align-items: center;
            padding: 20px 0px;
            overflow-y: scroll;

            &.open {
                animation: openMenu 0.8s ease-in-out forwards;
            }

            &.close {
                animation: closeMenu 0.8s ease-in-out forwards;
            }

            @keyframes openMenu {
                0% {
                    top: -100%;
                }

                100% {
                    top: 60px;
                }
            }

            @keyframes closeMenu {
                0% {
                    top: 60px;
                }

                100% {
                    top: -100%;
                }
            }

            div.header_container_item {
                width: 100%;
                padding: 0 15px;
                display: flex;
                align-items: center;
                gap: 5px;

                span {
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
        }

        div.layoutbotton_container {
            height: calc(100vh - 60px);
            border: none;
            border-radius: 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .bets-inputs_conatiner {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                height: fit-content;
                padding: 10px 0;

                .left_create-ticket {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    gap: 5px;
                    padding: 5px 10px;

                    div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        label {
                            font-size: 13px;
                            font-weight: 500;
                            text-transform: uppercase;
                            color: #f1f1f1;
                        }

                        input {
                            width: 100%;
                            border: none;
                            padding: 5px;
                            border-radius: 5px;
                            background-color: #f1f1f1;
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;

                            &:focus {
                                outline: none;
                                background-color: #fbe896;
                            }
                        }
                    }
                }
            }

            .amounts_container {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                height: fit-content;
                padding: 10px 0;
                background-color: #323232;

                div.div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    align-items: center;
                }

                label {
                    font-size: 12px;
                    font-weight: 400;
                    text-transform: uppercase;
                    color: #f1f1f1;
                }

                .input_label {
                    input {
                        width: 100%;
                        background: transparent;
                        text-align: center;
                        color: #f1f1f1;
                        border: none;
                        font-size: 12px;
                    }
                }
            }

            .leagues_container {
                height: calc(100vh - 270.5px);
                overflow-y: scroll;
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: stretch;
                align-content: flex-start;
                gap: 1px;

                div.theme4_leagues_mobile {
                    width: calc(25% - 1px);
                    height: fit-content;

                    a {
                        width: 100%;
                        text-decoration: none;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background-color: #434242;
                        padding: 20px 15px;

                        img {
                            width: 100%;
                            height: 50px;
                            object-fit: contain;
                        }
                    }

                    >div {
                        background-color: #656464;
                        width: 100%;
                        color: #f1f1f1;
                        display: flex;
                        justify-content: center;
                    }

                    button {
                        width: 100%;
                        text-decoration: none;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background-color: #434242;
                        padding: 20px 15px;
                        border: none;
                    }
                }
            }
        }

        .bets_container {
            width: 100%;

            .bets_container_title {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                padding: 10px 0;
                font-size: 16px;
                text-transform: uppercase;
                color: #f1f1f1;
            }

            table {
                width: 100%;
                border-collapse: collapse;

                thead {
                    background-color: #323232;
                    text-transform: uppercase;
                    color: #999;
                }

                tbody {
                    background-color: #696969;
                    color: #f1f1f1;
                }
            }
        }

        div.mobileKeyboard {
            display: flex;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 0px;
            position: absolute;
            bottom: 80px;
            left: 0;
            width: 100%;

            button.keybutton {
                background-color: #f1f1f1;
                border: 2px solid #000;
                padding: 5px 10px;
                font-size: 18px;
                font-weight: 500;
                color: #202020;
                text-transform: uppercase;
                border-radius: 2px;
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.mobileKeyboard--numbers {
                padding: 0 5px;

                button.keybutton {
                    font-size: 25px;
                    width: 33.33%;
                    height: 10vh;
                }
            }
        }

        div.footer_mobile {
            width: 100%;
            height: 45px;
            position: fixed;
            bottom: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3px;

            &>div {
                width: 16.66666666666667%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                font-size: 20px;
                color: #fff;
                position: relative;

                .bets {
                    position: absolute;
                    top: -15px;
                    width: 20px;
                    height: 20px;
                    background-color: #ce4751;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #fff;
                    font-weight: 500;
                    border: 1px solid #eeeff3;
                }

                &:nth-child(1) {
                    background-color: #397cb3;
                }

                &:nth-child(2) {
                    background-color: #63c95d;
                }

                &:nth-child(3) {
                    background-color: #e9ac53;
                }

                &:nth-child(4) {
                    background-color: #64cbdd;
                }

                &:nth-child(5) {
                    background-color: #ce4751;
                }
            }


        }
    }
}