@keyframes heartbeat {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .heartbeat {
    animation: heartbeat 1.5s infinite;
  }

  .small-input {
    width: 100px;
  }
  .flex-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Ajusta el espacio entre los elementos según sea necesario */
  }
  