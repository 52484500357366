:root {
  --background-color-t3: #0E2954;
  --text-color-t3: #6d6d6d;
  --text-color-secondary-t3: #fff;
  --buttons-gradient-t3: linear-gradient(180deg, #0A6EBD 36%, #0A6EBD 37%, #0A6EBD 100%);
  --buttons-gradient-disabled-t3: #04599b;
}

#LayoutClientt3 {
  background-color: var(--background-color-t3);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--text-color-t3);
  font-family: "Roboto", sans-serif;
  font-weight: 500;


  #table_bets_render_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: #edeff1;
    margin-top: 10px;
  }

  .navbar-brand.active {
    border: 2px solid #FBAE72;
  }

  .footer_container {
    // border: 1px solid var(--text-color-secondary-t3);
    height: 60px;

    .footer_button {
      background: var(--buttons-gradient-t3);
      border-radius: 10px;
      color: var(--text-color-secondary-t3);
      // border: 1px solid var(--text-color-secondary-t3);
      border: none;

      &:hover {
        transform: scale(1.05);
        background: #0663aa;
      }


    }

    .footer_button:disabled {
      background: var(--buttons-gradient-disabled-t3);

      &:hover {
        transform: scale(1);
        cursor: not-allowed;
      }
    }
  }

  .footer_container1 {
    // border: 1px solid var(--text-color-secondary-t3);
    height: 60px;
    background: #c0b8b8;
    border-width: 20px;
    border-color: #000;

    .footer_button {
      background: '#2d7dc4';
      border-radius: 10px;
      color: black;
      // border: 1px solid var(--text-color-secondary-t3);
      border: none;

      &:hover {
        transform: scale(1.05);
        background: #c42d2d;
      }


    }

    .footer_button:disabled {
      background: var(--buttons-gradient-disabled-t3);

      &:hover {
        transform: scale(1);
        cursor: not-allowed;
      }
    }
  }

  .button_to_add_bet button {
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--buttons-gradient-t3) !important;
    border-radius: 10px;
  }

  #left-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    select.betType {
      border: none;
      font-weight: bold;
      text-align: center;
      text-align-last: center;
    }
  }

  #right-container {
    #right_container {

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      position: relative;

      * {
        // font-size: 14px !important;
        font-weight: 700;
      }

      .button_container {

        .right_button {
          background: var(--buttons-gradient-t3);
          border-radius: 0px;
          color: var(--text-color-secondary-t3);
          border: none;
          gap: 3px;
          width: 100% !important;
        }

        .right_button a {
          width: 100% !important;

        }
      }

      select#tableToViewSelect {
        width: 100%;
        border: none;
        font-weight: 500;
        text-align: center;
        padding: 2px 0;

        &:focus {
          outline: none;
        }

      }

      .Tables_right_container {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        height: 55vh;
        gap: 10px;
      }

      .table-responsive {
        // min-width: 463px !important;
        // overflow: hidden;
      }

      .Tables_right_container::-webkit-scrollbar {
        width: 15px;
        height: 15px;
      }

      .Tables_right_container::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      .Tables_right_container::-webkit-scrollbar-thumb {
        background: #0d2d6a;
      }

      .table-responsive::-webkit-scrollbar {
        width: 1rem;
        height: 1rem;
      }

      .table-responsive::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      .table-responsive::-webkit-scrollbar-thumb {
        background: #000000;
      }

      .Tables_right_container .table-responsive table {
        width: 100%;
        border: 1px solid #0d2d6a;
        color: #000;

        // thead {
        //   th {
        //     padding: 0.5rem !important;
        //     font-size: 11px !important;
        //     font-weight: 800 !important;
        //     text-transform: uppercase !important;
        //     background-color: #0d2e6abe !important;
        //     color: #f1f1f1;
        //   }
        // }

        tbody {
          td {
            padding: 0rem !important;
            font-size: 11px !important;
            font-weight: 800 !important;
            text-transform: uppercase !important;
            background-color: #fff !important;
            white-space: nowrap !important;

            * {
              width: fit-content !important;
            }

            div.container-div {
              margin: 0 !important;
              padding: 0 !important;

              div.header-div {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 !important;
                font-size: 11px !important;
                font-weight: 800 !important;
                text-transform: uppercase !important;
                background-color: #00aaff !important;
                color: #f1f1f1;
                white-space: nowrap !important;
                width: 100% !important;
                text-align: center !important;
              }

            }
          }
        }
      }

      .table-responsive-t3 {

        .lines-games-vertical__table {
          width: 100% !important;
          display: flex;
          flex-direction: column;

          .lines-games-vertical__table__period {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }

      div#scrollvertical {
        overflow-y: scroll;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
          width: 15px;
          height: 15px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #0d2d6a;
        }
      }
    }
  }

  .bg-card {
    background-color: #001C30 !important;
    border-radius: 5px;
    border: solid 1px #0d2d6a;
    padding: 3px;
    font-size: 14px !important;
    border-bottom: none !important;
  }

  .bet-selected {
    background: linear-gradient(180deg, #ffdbb5 0%, #ffb65a 42%, #ffe794 85%, #fff 100%);
  }

  .border-bottom-ticket:before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: -6px;
    border-radius: 0 18px 18px 0;
    border-top: solid 1px #0d2d6a;
    border-right: solid 1px #0d2d6a;
    border-bottom: solid 1px #0d2d6a;
    width: 16px;
    height: 30px;
    background-color: #bdd4f4;
    /* background-color: black; */
    display: none;
  }

  .border-bottom-ticket:after {
    content: '';
    position: absolute;
    bottom: -15px;
    right: -7px;
    border-radius: 18px 0 0 18px;
    border-top: solid 1px #0d2d6a;
    border-left: solid 1px #0d2d6a;
    border-bottom: solid 1px #0d2d6a;
    width: 18px;
    height: 30px;
    background-color: #bdd4f4;
    display: none;
  }

  .bg-card3#header {
    background-color: transparent !important;
    border-radius: 5px;
    border: none;
    padding: 5px;
    font-size: 14px !important;


    nav {
      background-color: transparent !important;
      border-radius: 5px;
      border: none;
      padding: 5px;
      font-size: 14px !important;

      .navbar-brand.active {
        border: 2px solid #0d2d6a;
      }

      .navbar-brand {
        border-radius: 5px;
        font-size: 14px !important;
        margin: 0;
        padding: 2px 5px;
      }
    }
  }


  .ticket-top {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: none;
  }

  .ticket-top {
    height: 60% !important;
    padding-top: 15px;
  }

  .ticket-bottom {
    height: 40% !important;
  }

  .vertical__table__period__title {
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    color: #202020 !important;
  }

  span.periodight {
    color: #fff !important;
    text-transform: uppercase;
    font-size: 10px;
  }
}

// mobile version:

@media (max-width: 768px) {
  div.mobile_opener {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: transparent;
    z-index: 9999;
    display: flex;
    justify-content: flex-start;
    padding: 0 !important;
    margin: 0 !important;


    svg {
      width: 35px;
      height: 35px;
      fill: #fff;
      transition: all 0.5s ease-in-out;
      background-color: transparent;
      padding: 2px !important;
      // box-shadow: 0 0 13px #202020e0;
      border-radius: 5px;

    }
  }

  #LayoutClientt3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f9f9f9;
    overflow: hidden;

    .bg-card {
      background-color: #202020 !important;
      border-radius: 5px;
      border: solid 1px #0d2d6a;
      padding: 3px;
      font-size: 14px !important;
      border-bottom: none !important;
    }

    div.header-mobile {
      width: 100%;

      min-height: 80px;
      background-color: #3c464c;
      display: flex;
      flex-direction: column;

      div.header-mobile__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0px;
        min-height: 40px;
        z-index: 10;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-size: 18px;
          font-weight: 500;
          color: #f1f1f1;
          text-transform: uppercase;
        }

        div.header-mobile__top__icons {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          width: 100%;

          div svg {
            width: 25px;
            height: 25px;
            fill: #f1f1f1;
            transition: all 0.5s ease-in-out;
            background-color: #3c464c;
            padding: 2px !important;
            box-shadow: 0 0 13px #3c464ce0;
            border-radius: 5px;
          }
        }
      }

      div.header-mobile__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0px;
        min-height: 40px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-size: 14px;
          font-weight: 500;
          color: #f1f1f1;
          text-transform: uppercase;
        }

        div.odds {
          width: fit-content !important;
          padding: 0 10px;
        }

        div.active {
          border-bottom: 2px solid #0f8ec9;
        }
      }
    }

    footer {
      &.menu_open {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
      }

      .footer_container {
        position: absolute;
        left: -100%;
        top: 0;
        background-color: #ffffffb6;
        backdrop-filter: blur(5px);
        transition: all 0.5s ease-in-out;
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100vh;
        width: 80%;
        overflow: hidden;
        padding: 0 !important;

        &.active {
          left: 0;
        }

        .footer_button:disabled {
          background-color: #f1f1f1 !important;
          color: #202020;
        }

        .footer_button {
          width: 100%;
          height: 30px !important;
          text-align: center;
          background: #f2f2f2 !important;
          color: #202020;
          color: #202020;
          padding: 0 !important;
          border-radius: 0;
        }

        .footer_button:hover {
          background: #f2f2f2 !important;
          color: #202020;
        }

        div.list-label_and_p {
          display: flex !important;
          flex-direction: column !important;


        }


      }
    }

    div.leagues-container {
      width: 100%;

      .bg-card3#header {
        background-color: #fcfcfc !important;
        box-shadow: 0 0 10px #3c464c07;
        border-radius: 5px;
        border: none;
        padding: 5px;
        font-size: 14px !important;
        height: 45vh;
        width: 100%;

        nav {
          background-color: transparent !important;
          border-radius: 5px;
          border: none;
          padding: 5px;
          font-size: 14px !important;
          gap: 4px;
          max-height: 50vh;
          overflow-y: scroll;


          .navbar-brand {
            border-radius: 5px;
            font-size: 14px !important;
            margin: 0;
            border: 2px solid #afb5c2;
            background: rgb(32, 32, 32);
            background: linear-gradient(180deg, rgba(32, 32, 32, 1) 0%, rgba(241, 241, 241, 1) 100%);
          }

          .navbar-brand.active {
            // background-color: #202020 !important;
          }
        }
      }
    }

    div.footer-mobile {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      z-index: 98;

      div.footer-mobile__left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;

        select#ticketselec {
          width: 100%;
          border: none;
          font-weight: 500;
          text-align: center;
          padding: 2px 0;
          border-bottom: 1px solid #3c464c;

          &:focus {
            outline: none;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      div.footer-mobile__right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;

        .footer-mobile__right__icons {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 100%;

          i {
            font-size: 20px;
            color: #f1f1f1;
            transition: all 0.5s ease-in-out;
            background-color: transparent;
            padding: 2px !important;
            // box-shadow: 0 0 13px #3c464ce0;
            border-radius: 5px;
          }
        }
      }
    }

    div#keyboardmobile {
      // background-color: #202020;     
      position: absolute;
      bottom: -100%;
      z-index: 99;

      div.mobileKeyboard {
        display: flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 0px;

        button.keybutton {
          background-color: #f1f1f1;
          border: 2px solid #000;
          padding: 5px 10px;
          font-size: 18px;
          font-weight: 500;
          color: #202020;
          text-transform: uppercase;
          border-radius: 2px;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.mobileKeyboard--numbers {
          padding: 0 5px;

          button.keybutton {
            font-size: 25px;
            width: 33.33%;
            height: 10vh;
          }
        }
      }

      &.show {
        animation: showKeyboardUp 0.5s ease-in-out forwards;
      }

      @keyframes showKeyboardUp {
        0% {
          bottom: -100%;
        }

        100% {
          bottom: 20px;
        }
      }
    }

    #left-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: fit-content;
      width: 100%;
      margin-top: 10px;
      background-color: transparent;

      .bg-card {
        background-color: transparent !important;
        border: none !important;

        div.username {
          font-size: 14px !important;
          font-weight: 500;
          color: #202020;
          text-transform: uppercase;
        }

        input.datetime {
          border: none !important;
          border-radius: 5px;
          background-color: #f1f1f1;
          color: #202020;
          font-size: 14px !important;
          font-weight: 500;
          text-align: center;
          text-align-last: center;
        }

        div.inputs {
          gap: 3px !important;

          input {
            padding: 20px;
            text-align: center;

          }
        }
      }

      select.betType {
        border: none;
        font-weight: bold;
        text-align: center;
        text-align-last: center;
      }

      .ticket-top {
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: none;
        height: 60% !important;
        padding-top: 15px;
      }
    }

    .ticket-bottom {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      height: fit-content;
      padding: 0 5px;
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      color: #f1f1f1;

      button {
        background-color: #e9e9e9;
        border: none;
        margin-right: 8px;
        padding: 5px 10px;
        font-size: 16px;
        font-weight: 500;
        color: #202020;
        text-transform: uppercase;
      }

      div.ifcheckbox {
        .btn {
          border: 3px solid #1a1a1a;
          display: inline-block;
          // padding: 10px;
          position: relative;
          text-align: center;
          transition: background 600ms ease, color 600ms ease;
        }

        input[type="radio"].toggle {
          display: none;

          &+label {
            cursor: pointer;
            min-width: 60px;
            text-transform: uppercase;

            &:hover {
              background: none;
              color: #1a1a1a;
            }

            &:after {
              background: #1a1a1a;
              content: "";
              height: 100%;
              position: absolute;
              top: 0;
              transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
              width: 100%;
              z-index: -1;
            }
          }

          &.toggle-left+label {
            border-right: 0;

            &:after {
              left: 100%
            }
          }

          &.toggle-right+label {
            margin-left: -5px;

            &:after {
              left: -100%;
            }
          }

          &:checked+label {
            cursor: default;
            color: #fff;
            transition: color 200ms;
            background-color: #202020;

            &:after {
              left: 0;
            }
          }
        }
      }

      div.current-bet {
        color: #202020;
        font-size: 14px;
        font-weight: 500;

      }
    }

    #right-containerm {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      height: 100vh;
      width: 100vw;


      div.right_container_header {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 40px;
        // background-color: #001C30;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: 14px;
          font-weight: 500;
          color: #f1f1f1;
          text-transform: uppercase;

          button {
            background-color: transparent !important;
            border: none;

            svg {
              width: 35px;
              height: 35px;
              fill: #202020;
              transition: all 0.5s ease-in-out;
              background-color: #202020;
              padding: 2px !important;
              box-shadow: 0 0 10px #20202090;
              border-radius: 5px;
            }
          }
        }
      }

      .body-lines {
        .line-container {
          margin: 0 auto;
        }
      }

      div.tables_container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        div.table-line {
          min-width: 90vw;

          .container-div.time-game {
            min-width: 150px !important;
          }

          .container-div {
            width: 100% !important;
          }
        }

        #table-lines-responsive {}
      }
    }


  }

  .hide_mobile {
    display: none !important;
  }

  .mobile_keyboard {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 102;
    background-color: #202020;
    box-shadow: 0 0 10px #20202090;
    border-radius: 5px;
    padding: 3px !important;

    span {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
        fill: #f1f1f1;
      }
    }
  }

  #swal2-title {
    color: #202020 !important;
    font-size: 16px;
  }

  div.modal_reports {
    width: 99vw !important;
    max-width: 99vw !important;
    transform: scale(1) !important;

    .ant-modal-content {
      padding: 10px;

      .ant-modal-body {

        div#reportsmodalsimple {
          width: 90vw !important;
          max-width: 90vw !important;
          height: 100%;
          padding: 0 !important;
          margin: 0 !important;

          div.col-12 {
            padding: 0 !important;
            margin: 0 !important;
            display: flex !important;
            flex-direction: column !important;
            justify-content: flex-start !important;
            overflow-x: scroll !important;
            align-items: flex-start !important;

            div.d-flex.gap-4 {
              position: sticky !important;
              left: 1px !important;
            }
          }

          table.table-sm {
            font-size: 10px !important;
            width: 100% !important;
            border: 1px solid #20202040 !important;
            box-shadow: 0 0 10px #20202010;
            padding: 0 !important;

            thead {
              font-size: 9px !important;
            }

            tbody {
              width: 100% !important;

              tr {
                width: 100% !important;

                &:nth-child(odd) {
                  background-color: #f1f1f1;
                }

                &:nth-child(even) {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  div.ant-modal-body {

    // div {
    //   padding: 0 !important;
    // }

    div .label_and_p_container {
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-start !important;
      align-items: flex-start !important;
      width: 100%;
      height: 100%;
      padding: 0 !important;

      &.border-end {
        border: none !important;
      }

      label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 10px !important;
        font-weight: 500;
        color: #202020;
        text-transform: uppercase;
        white-space: nowrap;
      }

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 10px !important;
        font-weight: 500;
        color: #202020;
        text-transform: uppercase;
      }
    }
  }
}