@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');



.ticket-print {
    font-size: 5vw !important;
    font-family: 'Roboto', sans-serif !important;
    /* font-size: 16px; */
    print-color-adjust: exact !important;
    color: #000 !important;
    // background-color: #000;
    letter-spacing: 0;
    width: 100vw;

    -webkit-print-color-adjust: exact !important;
    -webkit-text-size-adjust: initial !important;
    -webkit-tap-highlight-color: initial !important;
}


.ticket-header {
    font-family: 'Roboto', sans-serif !important;
    border-bottom: dashed .1em #000;
    padding: 2em 0 1em;
    text-align: center;


    // img {
    //     text-align: center;
    //     width: 100%;
    // }

    img.filter-img {
        filter: grayscale(1)
    }

    h1 {
        font-weight: 700;
        font-size: 2em;
        margin: 0
    }

    h2 {
        font-size: 1.5em;
        margin: 0
    }

    p {
        font-size: 1.3em;
        font-weight: bold;
        color: #fff;
        background-color: #000;
        margin-bottom: 0;
    }

    p.background-send {
        background-color: #fc1010;
        color: #fff;
    }

    div.consortium {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 150px ;
            height: 150px;
            // max-height: 50px;
            // max-width: 40px;
            // object-fit: contain;
        }

        h1 {
            font-size: 1.2em;
            margin: 0;
            color: rgb(83, 82, 82);
            text-transform: uppercase;
        }
    }
}
.info-game {
    font-family: 'Roboto', sans-serif !important;
    padding: .2em 0 1em;
    text-align: center;
}
.ticket-body {
    font-family: 'Roboto', sans-serif !important;
    padding: .2em 0 1em;

    table {
        width: 100%;

        tr th {
            border-bottom: .1em dashed #000;
        }

        tr td,
        tr th {
            font-size: 0.90em;
        }


        tbody tr td {
            border: none !important;
            padding-bottom: .4em;
           
        }

        tfoot tr td {
            text-align: end;
        }
    }

}

.ticket-body table tbody tr td {
    padding: 0 10px; /* Ajusta este valor según el espacio que desees */
  }
  

.ticket-body table th {
    padding: 0 15px; /* Ajusta este valor según el espacio que desees */
  }

  

.ticket-footer {
    font-family: 'Roboto', sans-serif !important;
    border-top: dashed .1em #000;
    border-bottom: dashed .1em #000;
    padding: 2em 0 3.5em;
    text-align: center;
    font-size: 1em;

    p {
        margin-bottom: 0;
    }
}

.table-footer {
    width: 100%;
    margin: 0 auto; 
    text-align: center;
    border-top: dashed .1em #000;
  }
  
  .table-footer table {
    display: inline-table; 
    margin-top: 10px;
  }

  .table-footer thead td {
    font-weight: bold;
  }
  
  


// @media print {
//     @page {
//       size: 80mm auto;
//     }
//   }