@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap');

#pageScores {
    font-family: 'Oswald', sans-serif;
    font-size: 16px;

    .dropdown.drop-pok-btn {
        display: flex;
        align-items: center;
    }

    button.dropdown-toggle {
        color: #aeaeae !important;
        background-color: none !important;
        font-weight: 500;
        padding: 0.5rem .5rem;

        &:hover {
            color: #000 !important;
        }
    }

    header button.dropdow-toggle {
        font-size: 1.1em;
    }

    @media only screen and (min-width:992px) {
        header nav ul.navbar-nav {
            display: flex;
            flex-direction: column;
        }

        #navbarScores .col-12 {
            margin-bottom: 1.25em;
        }

        .scorelogo-header img {
            width: 100%;
            height: auto;
        }

        .scorelogo-header {
            margin-right: 0 !important;
        }

        #navSidebarScores ul li a.active::after {
            display: block;
            position: absolute;
            top: 50%;
            left: -1.4em;
            width: 1.7em;
            height: 2px;
            content: '';
            background-color: #000;
        }
    }

    .scorelogo-header img {
        width: 100px;
        height: auto;
    }

    .side-wrapper {
        margin-top: 1.25em;
    }

    #navSidebarScores ul li {
        text-align: center;

        a {
            color: #aeaeae !important;
            font-weight: 500;

            &:hover {
                color: #000 !important;
            }

            &.active {
                color: #000 !important;
                position: relative;
            }
        }

    }

    .sbg-header-score {
        // color: #000 !important;
        // background: red; 
        /* position: fixed; */
        /* width: 100%; */
        /* top: 0; */
        z-index: 9999;
    }

    .scorelogo-header {
        color: #000 !important;
        font-size: 2.5em;
        line-height: 1.5em;
        font-weight: 600;
        text-transform: uppercase;
    }

    .sbrand-header {
        color: #000 !important;
        font-size: 34px;
        line-height: 1.5em;
        font-weight: 600;
        text-transform: uppercase;
        margin-left: 15px;
    }

    .navbar-toggler-icon svg {
        color: #000 !important;
        width: 2.1em;
        height: 2.1em;
        border-radius: 3px;
    }

    #navbarSportsScores ul {
        // border-bottom: 1px solid #aeaeae;
    }

    #navbarSportsScores ul li a.nav-link {
        color: #aeaeae;
        font-weight: 500;
        font-size: 1.1em;

        &:hover {
            color: #000 !important;
        }

        &.active {
            color: #000 !important;
            border-bottom: 2px solid #000;
        }
    }

    .scores-title {
        border-top: 1px #aeaeae solid;
        border-bottom: 1px #aeaeae solid;
        padding-top: 1em;
        padding-bottom: 1em;
        margin-bottom: 1em;
    }

    #scores-content .row {
        width: 100%;
    }

    #scores-content .team img {
        width: 2.5em;
        padding: .6em 0;
    }

    #scores-content .team span {
        color: #000;
        font-weight: 500;
        font-size: 1.1em;
    }

    #scores-content .team {
        padding: .6em 0 .6em .6em;
        border-bottom: 1px solid #e5e5e5;
        transition: all .2s ease-in-out;
    }

    #scores-content .team:hover {
        background-color: #f5f5f5;
        transition: all .2s ease-in-out;
    }

    #scores-content .team span.small {
        font-size: .75em;
    }

    #scores-content .team span.second {
        color: #949494;
    }

    #scores-content .team h5,
    #scores-content .team span.final {
        font-size: .75em;
        font-weight: normal;
    }

    #scores-content h4 {
        color: #000;
        font-size: 1.6em;
        font-weight: 600;
    }


    @media only screen and (min-width: 768px) {
        #scores-content .team:nth-child(odd) .team-name {
            padding-right: 1.25em;
            border-right: 1px solid #e5e5e5;
        }

    }

    @media only screen and (max-width: 991px) {
        #navbarSportsScores {
            border-bottom: 1px solid #5655678c;
            height: 4.3em;
        }

        #navSportScore {
            background-color: #fff;
            border: 1px #000 solid;
            border-top: none;
        }

    }

    @media only screen and (max-width: 767px) {
        .col-space-blank {
            display: none;
        }
    }



    .drop-pok-btn.sidebar {
        margin-left: 0;
        margin-top: 0;
        margin: 0 auto;
    }

    .mainStyles {
        min-height: 50em;
        background-color: #fff;
    }

    #menuIcons a {
        margin: 0 !important;
        color: #00000069 !important;
    }

}