/*button*/

.button-container {
    display: flex;
    justify-content: center; /* Centrado horizontal */
    align-items: center; /* Centrado vertical */
    width: 40%;
    height: 8%;
    border: solid 3px slategray;
    border-radius: 10px;
    margin-left: 122px;
    margin-bottom: 5px;
    background-color: white;
}