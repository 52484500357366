$color-primary: #CC2F48;
$color-secondary: #212529;
$color-terciary: #282838;
$color-light: #f6f6f6;
$text-color: #FFFFFF;


#bodyLogin {
    background-color: $color-terciary;

    .rowLogin {
        height: 100vh;

        .colLogin {
            background-color: $color-secondary;
            padding: 50px;
            border-radius: 15px 15px 0 0;
            border-bottom: 5px solid $color-primary;

            img {
                margin-bottom: 20px;
                width: 150px;
            }

            label {
                color: $text-color;
                font-weight: 500;
            }

            button {
                background-color: $color-primary;
                border: none;
                margin-top: 10px;
                padding: 10px 40px;

                &:active:focus {
                    box-shadow: 0 0 0 0.25rem rgba(204, 47, 72, .5) !important;
                }
            }
        }
    }

    .titleLogin {
        color: $text-color;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .subtitleLogin {
        color: $text-color;
        font-weight: 500;
        margin-bottom: 20px;
    }
}