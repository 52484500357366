html {
    font-family: sans-serif;
}

:root {
    --primary: #000 !important;
}


.loader {
    position: relative;
    width:  100px;
    height: 100px;
    background: #CC2F48;
    transform: rotateX(65deg) rotate(45deg);
    /* remove bellows command for perspective change*/
    /*transform: perspective(200px) rotateX(65deg) rotate(45deg);*/
    color: #212529;
    animation: layers1 1s linear infinite alternate;
}
.loader:after {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(33, 37, 41, 0.7);
    animation: layerTr 1s linear infinite alternate;
}

@keyframes layers1 {
    0% { box-shadow: 0px 0px 0 0px  }
    90% , 100% { box-shadow: 20px 20px 0 -4px  }
}
@keyframes layerTr {
    0% { transform:  translate(0, 0) scale(1) }
    100% {  transform: translate(-25px, -25px) scale(1) }
}

.loader-words{
    font-size: 48px;
    color:  rgba(33, 37, 41, 0.9);
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    position: relative;
}
.loader-words:before{
    content: '';
    animation: 5s print linear alternate infinite;
}
.loader-words:after{
    content: '';
    position: absolute;
    right: -4px;
    top: 50%;
    transform: translatey(-45%);
    width: 2px;
    height: 1.3em;
    background: currentColor;
    opacity: 0.8;
    animation: 1s blink steps(2) infinite;
}

@keyframes blink {
    0%  { visibility: hidden;}
    100%  { visibility: visible;}
}
@keyframes print {
    0% { content: 'C'}
    10% { content: 'Ca'}
    20% { content: 'Car'}
    30% { content: 'Carg'}
    40% { content: 'Carga'}
    50% { content: 'Cargan'}
    60% { content: 'Cargand'}
    70% { content: 'Cargando'}
    75% { content: 'Cargando.'}
    80% { content: 'Cargando..'}
    90% , 100% { content: 'Cargando...'}
}
