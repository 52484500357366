.parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 3px;
    grid-row-gap: 3px;

    button {
        width: 100%;
        height: 100%;
    }
}

.div1 {
    grid-area: 1 / 1 / 2 / 2;
}

.div2 {
    grid-area: 1 / 2 / 2 / 3;
}

.div3 {
    grid-area: 1 / 3 / 2 / 4;
}

.div4 {
    grid-area: 1 / 4 / 2 / 5;
}

.div5 {
    grid-area: 2 / 1 / 3 / 2;
}

.div6 {
    grid-area: 2 / 2 / 3 / 3;
}

.div7 {
    grid-area: 2 / 3 / 3 / 4;
}

.div8 {
    grid-area: 2 / 4 / 4 / 5;
}

.div9 {
    grid-area: 3 / 1 / 4 / 2;
}

.div10 {
    grid-area: 3 / 2 / 4 / 3;
}

.div11 {
    grid-area: 3 / 3 / 4 / 4;
}

.div12 {
    grid-area: 4 / 1 / 5 / 2;
}

.div13 {
    grid-area: 4 / 2 / 5 / 3;
}

.div14 {
    grid-area: 4 / 3 / 5 / 4;
}

.div15 {
    // grid-area: 4 / 4 / 6 / 5; //version con los otros botones xd
    grid-area: 6 / 4 / 2 / 5;
}

.div16 {
    grid-area: 5 / 1 / 6 / 3;
}

.div17 {
    grid-area: 5 / 3 / 6 / 4;
}