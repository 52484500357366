/*thead*/

/* .table-header-container {
    width: 100%;
   
} */


.table-header-container th {
    background-color: #dc3545 !important;
    color: white;
    padding: 11px;
    margin: auto;
    text-align: center;
    
}