.toggle-container {
    width: 130px; 
    height: 33px; /* Ajusta el alto según sea necesario */
    position: relative;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .toggle-container .status {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* transform: translateX(-20%); */
    color: #fff;
    font-size: 14px;
    padding: 0 5px;
    /* left: 35px; */
  }

  .toggle-container.blocked .status {
    left: 20px; /* Ajusta la posición del texto para el estado Blocked */
  }
  .toggle-container.unblocked .status {
    left: 37px; /* Ajusta la posición del texto para el estado Blocked */
  }
  
  .toggle-container .ball {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: #ccc;
    position: absolute;
    top: 50%;
    left: 10px; /* Ajusta la posición de la pelotita según sea necesario */
    transform: translateY(-50%);
    transition: left 0.3s ease-in-out;
  }
  
  .toggle-container.blocked {
    background-color: #808080; /* Cambia el color de fondo para el estado Blocked */
  }
  
  .toggle-container.unblocked {
    background-color: #4caf50; /* Cambia el color de fondo para el estado Unblocked */
  }
  
  .toggle-container.blocked .ball {
    left: calc(100% - 40px); /* Mueve la pelotita hacia la derecha para el estado Blocked */
  }
  
  .toggle-container.unblocked .ball {
    left: 10px; /* Vuelve a la posición inicial para el estado Unblocked */
  }
  
  .default_line  {
    color: black;
    font-size: 20px !important;
    font-weight: bold;
    border: 3px solid black;
    border-radius: 5px;
    padding: 3px;
  }

  .time_bot {
    color: black;
    font-size: 15px !important;
    font-weight: bold;
    border: 3px solid black;
    border-radius: 5px;
    padding: 3px;
  }