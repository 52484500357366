#table-dinamic-mobile {

  overflow-y: auto;
  height: 70vh;

  &::-webkit-scrollbar-thumb {
    background: #dc3545;
    border-radius: 2px;
  }

  /* Cambiar color track */
  &::-webkit-scrollbar-track {
    background: #f8f8f8;
  }

  /* Ancho del scrollbar */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  div#headertable {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
  }

  table {
    width: 100%;
    max-width: 1200px;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto;
    position: relative;

    * {
      font-size: 12px;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
    }

    thead {
      background-color: #dc3545;
      color: #fff;

      * {
        text-align: center !important;
      }

      tr {
        th:not([colspan="2"]) {
          padding: 8px;
          text-align: left;
          border: 2px solid #222020;

          &:nth-child(2) {
            border-left: none !important;
          }

          text-transform: uppercase;
          font-weight: 900;

          &.border-end {
            border-right: 3px solid #202020 !important;
          }
        }

        th[colspan="2"] {
          // padding: 8px;
          // text-align: left;
          // border: 2px solid #222020;
          text-transform: uppercase;
          font-weight: 900;
          border-top: 2px solid #222020;
          border-bottom: 2px solid #222020;
          min-width: 10vw;

          div {
            // position: absolute;
            // top: 0;
            // left: 0;
            width: 100%;
            height: 100%;
            border-left: 2px solid #222020;
            border-right: 2px solid #222020;
            z-index: 100;
          }
        }
      }
    }

    tbody {
      tr {
        &:not(.tr_matchups) {
          height: 60px !important;
        }

        overflow-y: hidden !important;
        border: 2px solid #222020;

        img {
          width: 29px;
          height: 29px;
          object-fit: cover;
        }

        &:nth-child(even) {
          background-color: #f2f2f2;
        }

        td {
          padding: 0px;
          text-align: left;
          border: 1px solid #00000023;
          height: 50%;
          max-height: 60px !important;

          &.games_container {
            padding-left: 20px !important;
            width: 300px !important;
          }

          .games_container {
            width: 100%;
          }

          &.default {
            >div {
              display: flex;
              justify-content: center !important;
              padding-left: 4px;
            }

            div>div {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
              position: relative;
            }

            div.games_container {

              background-color: #49ab63;

              div {
                display: flex;
                align-items: center;
                justify-content: start;
                height: 100%;
                max-width: 100px;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;

                >div {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  width: 100%;
                }
              }
            }
          }


          div.container_inputs {
            &:not(.container_inputs_matchups) {
              height: 60px !important;
            }

            &.container_inputs_matchups input {
              padding: 0.5rem 0;
            }

            display: flex;
            flex-direction: column;

            div {
              height: 50% !important;

              input {
                width: 100%;
                height: 100%;
                border: none;
                background-color: transparent;

                &:focus {
                  outline: none;
                }
              }

              &.approved {
                background-color: #9ae3ac !important;
              }

              &.pending {
                background-color: #f49999 !important;
              }

              &.automatic {
                background-color: #7dd3fc !important;
              }

              &.line-selected {
                border: black 3px solid !important;
              }

              &.changed {
                border: red 3px solid !important;
              }

              &:nth-child(2) {
                border-top: #49ab63 1px solid;
              }

              &.approved.bg-opacity-50 {
                background-color: rgb(149, 246, 171) !important;
              }

              &.manual {
                background-color: #050508 !important;
                color: #f2f2f2 !important;
              }
            }
          }

          &.border-end {
            border-right: 3px solid #202020 !important;
          }
        }

        td[colspan="2"] div span {
          white-space: nowrap;
        }
      }
    }

    tr {

      th:first-child,
      td:first-child {
        // position: sticky;
        // left: 0;
      }

      th:first-child {
        background-color: rgb(220, 53, 69);
      }

      td:first-child {
        background-color: white;
      }
    }

    &.table-simple {
      max-width: 800px;

      .games_container {
        width: 75% !important;
        overflow-x: hidden !important;
      }
    }

  }

  table.w-100.table-simple {
    // background-color: #050508;

    th {
      max-width: 250px !important;
      width: fit-content !important;
      // background-color: #202020;
    }

    td {
      max-width: 250px !important;
      width: fit-content !important;
    }
  }

  thead th {
    position: sticky !important;
    top: 0 !important;
    z-index: 99;
    background-color: #dc3545 !important;
  }

  table {
    width: 100% !important;
    border-collapse: separate !important;
    border-spacing: 0 !important;

    * {
      font-size: 13px;
    }

    tbody {
      tr td {
        border-bottom: 3px solid #050508 !important;

        &:nth-child(odd) {
          border-right: 2px #333 solid;
        }

        &:nth-child(even) {
          border-right: 2px #333 solid;
        }

        // &:last-child {
        //   border-right: none;
        // }
        &:first-child {
          border-left: 2px #333 solid;
        }

        &.border-end {
          border-right: #050508 3px solid !important;
        }

      }
    }
  }

  @media (min-width: 1660px) {
    table {
      th {
        border-width: 3px !important;
      }

      td {
        border-width: 3px !important;
      }
    }
  }



  // th,
  // td {
  //   padding: 8px 16px !important;
  //   border: 1px solid #ccc !important;
  // }

  // th {
  //   background: #eee !important;
  // }
}