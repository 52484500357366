#sidebar {
    background-color: #3f333f;
    width: 220px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.5rem;
    gap: 1rem;

    .sidebar__logo {
        .sidebar__logo-img {
            width: 80px;
            height: 80px;
            object-fit: contain;
        }

        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sidebar__menu {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            li {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                .sidebar__link {
                    i {
                        font-size: 1rem;
                    }

                    span {
                        font-size: 14px;
                    }

                    text-decoration: none;
                    color: #f3f3f3;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1rem;
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    background-color: #cc2f48;
                }

                

                .sidebar__link.active {
                    border-left: 3px solid #cc2f48;
                    padding-left: 0.5rem;
                    
                    
                }
            }
        }

        .sidebar__bot {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 0 0 0.5rem 0.5rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            color: #f3f3f3;
            margin-top: 1rem;
            // border-top: 1px solid #f3f3f3;

            i {
                font-size: 1rem;
            }

            span {
                font-size: 1rem;
            }

            &:hover {
                background-color: #cc2f48;
            }
        }

        .sidebar__logout {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 0 0 0.5rem 0.5rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            color: #f3f3f3;
            margin-top: 2rem;
            border-top: 1px solid #f3f3f3;

            i {
                font-size: 1rem;
            }

            span {
                font-size: 1rem;
            }

            &:hover {
                background-color: #cc2f48;
            }
        }
    }

}

#chatBot {
    // background-color: black;
    width: auto;
    height: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 2rem;
    margin-bottom: 2rem;
    z-index: 9999;
}





.Head {
    display: flex;
    align-items: center;
    background: #cc2f48;
    height: 50px;

}

.eclipse {
    width: 10px;
    height: 10px;
    position: relative;
    top: 13px;
    left: -22px;
    border: 1px;
    background: linear-gradient(0deg, #81C784, #81C784),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    grid-row: 2;
    grid-column: span 1;
}

.text1 {
    width: 58px;
    height: 15.5px;
    position: relative;
    top: -5px;
    left: 35px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    grid-row: 1;
    grid-column: span 1;
}

.text2 {
    width: 36px;
    height: 12.24px;
    position: relative;
    top: 13px;
    left: -20px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #E9E9E9;
    grid-row: 2;
    grid-column: span 1;
}

.logout {
    width: 70px;
    height: 25px;
    position: relative;
    top: 7px;
    left: 120px;
    border-radius: 6px;
    background-color: rgb(54, 53, 53);
    color: white;

}

.group1 {
    width: 35px;
    height: 35px;
    position: relative;
    left: 20px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
}


.group2 {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    cursor: pointer;
    // border: 1px solid #FFFFFF;
    // border-radius: 50%;
}


.svgMenu {
    width: 40px;
    margin: 5px;
}


@media (max-width: 570px) {
    .logout {
        left: 60px;

    }
}



// #sidebar {
//     /* Estilos generales del sidebar */
  
//     .sidebar__menu {
//       ul {
//         /* Estilos de la lista en el menú */
//         display: flex;
//         flex-direction: column;
//         padding-left: 0;
  
//         li {
//           /* Estilos de cada ítem del menú */
//           padding: 10px;
//           text-align: center;
  
//           a {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             text-decoration: none;
//             color: inherit;
//           }
//         }
//       }
//     }
  
//     @media (max-width: 767px) {
//       /* Media query para dispositivos pequeños (tamaño de pantalla de celulares) */
        
//       .svgMenu {
//         z-index: 1;
//       }
//       .sidebar__menu {
//         width: 50%; /* Ocupar el ancho completo */
//         position: absolute;
//         top: 0;
//         left: 0;
//         background-color: #333; /* Color de fondo para el acordeón */
//         z-index: 1;
//         height: 100vh; /* Altura completa */
//         padding: 5px;
  
//         ul {
//           flex-direction: column;
//           align-items: stretch;
  
//           li {
//             width: 100%; /* Ocupar el ancho completo */
//             padding: 10px;
//             text-align: left;
  
//             a {
//               width: 100%; /* Ocupar el ancho completo */
//             }
//           }
//         }
//       }
//     }
//   }
  
  
 
  