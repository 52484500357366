#carrousel_slider {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    #view_1 {
        height: 100%;
        width: 100%;
        position: absolute;
        left: calc(100vw * -1);
        transition: all 0.3s ease;
        background-color: #0d2d6a;
    }

    #view_2.tmobile {
        position: absolute;
        left: calc(100vw * -1);
        transition: all 0.3s ease;

        width: 100%;
        height: 100%;

        .tmobile__container {
            height: calc(100vh - 62px);
            display: flex;
            flex-direction: column;

            .tmobile__add_bet {
                width: 100%;
                max-height: 432px !important;
            }

            .tmobile__bets {
                width: 100%;
                height: 100%;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-track {
                    background: #3765bb;
                }

                &::-webkit-scrollbar-thumb {
                    background: #2fff00;
                }

                table.tmobile__bets_table {
                    width: 100%;
                    border-collapse: collapse;
                    border-spacing: 0;
                    border: 1px solid #ddd;
                    color: #000;
                    text-align: center;

                    thead {
                        background-color: #3765bb;
                        color: #fff;
                    }

                    tbody {
                        tr {
                            &:nth-child(even) {
                                background-color: #f2f2f2;
                            }

                            &:hover {
                                background-color: #ddd;
                            }
                        }
                    }

                    th,
                    td {
                        padding: 2px;
                    }
                }
            }

            .tmobile__keyboard {
                width: 100%;
                height: 100%;

                .keyboard_container {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(4, 1fr);
                    grid-gap: 8px;
                    width: 100%;
                    height: 100%;
                    padding: 10px;

                    .keyboard__key {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #3765bb;
                        color: #fff;
                        font-size: 1.5rem;
                        border-radius: 10px;
                        cursor: pointer;
                        transition: all 0.3s ease;
                        border: none;

                        &:hover {
                            box-shadow: #0d2d6a 2px 2px 1px 1px;
                        }
                    }
                }
            }
        }

    }

    #view_3 {
        position: absolute;
        left: 100vw;
        transition: all 0.3s ease;
        background-color: #2fff00;
        width: 100%;
        height: 100%;
    }


    #view_1.active,
    #view_2.active,
    #view_3.active {
        left: 0;
    }

}

#gamesforuserRight {
    height: 100vh !important;
    overflow-y: scroll !important;

    .Tables_right_container {
        width: 99vw !important;
    }

    table {
        width: 100% !important;
        border-collapse: collapse !important;
        border-spacing: 0 !important;
        border: 1px solid #ddd !important;
        color: #000 !important;
        text-align: center !important;
        font-size: 14px !important;
        font-weight: bold !important;

        thead {
            background-color: #3765bb !important;
            color: #fff !important;
        }

        tbody {
            tr {
                height: fit-content !important;

                &:nth-child(even) {
                    background-color: #f2f2f2 !important;
                }

                &:nth-child(odd) {
                    background-color: #fff !important;
                }
            }
        }

        th,
        td {
            padding: 2px !important;
        }

    }
}

.footer {
    height: 100vh !important;
    width: 100vw !important;
    overflow-y: scroll !important;
    display: flex;
    flex-direction: column;

    .footer_container {
        width: 100vw !important;
        height: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        button {
            width: 100vw !important;
            height: 100% !important;
            background-color: #3765bb !important;
            color: #fff !important;
            font-size: 1.5rem !important;
            cursor: pointer !important;
            transition: all 0.3s ease !important;
            border: none !important;

            &:hover {
                box-shadow: #0d2d6a 2px 2px 1px 1px !important;
            }

            &[disabled] {
                background-color: #355da7 !important;
                color: #fff !important;
                cursor: not-allowed !important;
            }
        }
    }
}