@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$color-nav: #f1f1f1;
$color-secondary: #cc2f48;
$color-primary: #282838;
$color-terciary: #22222d;
$color-text: #fff;
$hr-color: #c8c9ca;

#bodyAdmin {
  @media only screen and (min-width: 992px) {
    #navbarNav {
      width: 100%;
    }

    #navbarAdmin.navbar-expand-lg .navbar-collapse {
      display: block !important;
    }

    font-family: 'Montserrat',
    sans-serif;
  }

  .navbar svg {
    width: 100%;
    height: auto;
    color: #fff;
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 2px #fff !important;
  }

  @media only screen and (max-width: 991px) {
    .sidebar li a.nav-link {
      padding: 10px 20px;
      margin-bottom: 5px;
    }
  }

  @media only screen and (min-width: 992px) {
    .sidebar {
      min-height: 100vh;
    }
  }

  .sidebar {
    background-color: $color-primary;
    box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.3);
    padding: 15px;
    z-index: 10;
  }

  .sidebar .logo {
    padding: 10px;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .sidebar .logo img {
    width: 80%;
  }

  .sidebar .logo span {
    font-weight: bold;
    font-size: 24px;
    color: #fff;
    text-align: center;
  }

  .sidebar ul {
    border-top: 1px solid #c8c9ca;
    list-style: none;
  }

  .sidebar .salir {
    border-top: 1px solid #c8c9ca;
    margin-top: 20px;
  }

  .sidebar li .active:hover {
    background-color: $color-secondary !important;
  }

  .sidebar li {
    button {
      width: 100%;
      text-align: start;
    }

    a,
    button {
      background: none;
      border: none;
      text-decoration: none;
      font-size: 18px;
      color: #fff;
      font-weight: 500;
      padding: 5px 20px;
      transition: all 0.2s ease-in-out;
      display: block;
    }

    &:hover {

      a,
      button {
        color: #fff;
        background-color: $color-secondary;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .sidebar li .active {
    color: #fff !important;
    background-color: $color-secondary;
  }

  #containerHome {
    min-height: 650px;
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    background-color: #e0e0e0;
  }

  // scroll style
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #cc2f48;
  }

  body,
  .body {
    background-color: #eeeff3;
  }

  .bg-card {
    background-color: #e0e0e0;
    border-radius: 5px;
    // box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
    padding: 15px;
    border: none;
  }

  .body .logo-inicio img {
    width: 30%;
    text-align: center;
  }

  .body button,
  .modalLoterias .nav-tabs li button,
  .modal-terminals .nav-tabs li button,
  .admin-tools .nav-tabs li button,
  #createGroupModal .nav-tabs li button {
    background-color: $color-nav;
    border: 2px solid $color-nav;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 5px;
    margin: 0 5px;
    color: #333;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    text-align: center;
    width: 100%;
    box-shadow: 2px 2px 10px 0px rgba(46, 44, 44, 0.171);
  }

  .body button:hover {
    background-color: #fff;
    color: #333;
    transition: all 0.2s ease-in-out;
  }

  .body .mensajeria {
    max-width: 1200px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 10px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .body .mensajeria .mensajeria-fields {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0;
    padding: 0 0;
    width: 100%;
    gap: 10px;

    .mensajeria-field {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      max-width: 300px;

      select {
        width: 100%;
        padding: 5px 10px;
        border: 1px solid #c8c9ca;
        border-radius: 5px;
        font-size: 16px;
        color: #000;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
      }

      button {
        width: 100% !important;
        padding: 5px 10px !important;
        border: 1px solid #c8c9ca !important;
        border-radius: 5px !important;
        font-size: 16px !important;
        color: #ececec !important;
        font-weight: 500 !important;
        transition: all 0.2s ease-in-out !important;
        background: #000;

        &:hover {
          background-color: #cc2f48 !important;
          color: #fff !important;
          transition: all 0.2s ease-in-out !important;
        }
      }
    }
  }

  .body .mensajeria .mensajeria-cuerpo {
    padding: 0;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;

    textarea {
      width: 100%;
      padding: 5px 10px;
      border: 1px solid #1b1b1b;
      border-radius: 5px;
      font-size: 16px;
      color: #000;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      background-color: #ffffff;
    }

  }

  .body a.navlink-btn {
    background-color: $color-nav;
    border: 2px solid $color-nav;
    border-radius: 5px;
    font-size: 18px;
    padding: 5px 15px;
    margin: 0 5px;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    text-align: center;
    width: 100%;
  }

  .body a.navlink-btn:hover {
    background-color: #fff;
    color: $color-nav;
    transition: all 0.2s ease-in-out;
  }

  .body .button-nav-border {
    border-bottom: 1px solid #c8c9ca;
  }

  .body .button-nav button,
  .body .button-nav h3 {
    background-color: $color-nav;
    border: 2px solid $color-nav;
    border-radius: 5px;
    font-size: 18px;
    padding: 5px 25px;
    color: #fff;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    text-align: center;
  }

  .body .button-nav h3 {
    background: none;
    border: none;
    /* text-decoration: underline; */
    color: $color-nav;
  }

  .body .button-nav button:hover {
    background-color: #fff;
    color: $color-nav;
    transition: all 0.2s ease-in-out;
  }

  .body h2 {
    text-align: center;
    color: #202020;
    font-size: 26px;
    font-weight: bold;
  }

  .body h5 {
    font-size: 22px;
    color: #6d6d6d;
    text-align: center;
  }

  .body label {
    font-size: 17px;
    color: #6d6d6d;
    text-align: center;
    margin-right: 5px;
  }

  #accounting {
    label {
      font-size: 1.5rem !important;
      font-weight: 100 !important;
      color: red;
    }
  }

  .body input:not([type="radio"]),
  .body textarea {
    width: 100%;
    border: 1px solid #ccc;
    font-size: 18px;
    border-radius: 5px;
    padding: 2px 5px;
  }

  .body input[type="radio"] {
    margin-right: 5px;
  }

  .body input:focus,
  .body textarea:focus {
    outline: 2px solid $color-nav;
  }

  .body select {
    background-color: #eeeff3;
    padding: 5px;
    border-radius: 5px;
    border: none;
    width: 100%;
  }

  .body input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .body .table> :not(:first-child) {
    border-top: none;
  }

  // .body table {
  //   min-height: 200px;
  // }

  .body table thead th {
    background-color: #3f333f;
    color: #fff;
  }

  .body .tablaTotal {
    min-height: 30px !important;
    background-color: #eeeff3;
  }

  .body table thead.table-title {
    min-height: 30px;
    height: 30px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 991px) {
    .bg-card {
      padding: 30px;
    }

    .body .logo-inicio img {
      width: 60%;
    }

    .body label {
      font-size: 18px;
      color: #6d6d6d;
      text-align: center;
      margin-right: 5px;

    }

    #accounting {
      label {
        font-size: 1.5rem !important;
        font-weight: 100 !important;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .d-custom-block {
      display: block;
    }

    .button-nav button {
      font-size: 15px !important;
    }
  }

  @media only screen and (max-width: 575px) {
    .button-nav button {
      min-height: 60px;
    }
  }

  .modalLoterias .nav-tabs li,
  .modal-terminals .nav-tabs li button {
    margin-right: 10px !important;
  }

  .dropdown {
    width: 96%;
  }

  button#dropdownMenuButtong {
    margin: 0;
  }

  // .table-Venta-Admin thead tr th:first-child,
  // .table-Venta-Admin tbody tr td:first-child {
  //   // width: 50px;
  // }

  .table-Venta-Admin svg {
    width: 30px;
    height: 30px;
    background-color: $color-nav;
    color: #fff;
    border-radius: 5px;
    padding: 5px;
  }

  .table-Venta-Admin tbody tr:hover {
    cursor: pointer;
  }

  .table-Venta-Admin tr:hover svg {
    background-color: rgb(236, 236, 236);
    color: $color-nav;
  }

  @media only screen and (min-width: 991px) {
    .col-lg-1-custom {
      width: 11.11%;
    }
  }

  #editValues .nav-tabs li button {
    background-color: $color-nav;
    border: 2px solid $color-nav;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 5px;
    margin: 0 5px;
    color: #fff;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    text-align: center;
    width: 100%;
  }

  #containerPrizesUS span,
  #newMessageForm span {
    font-weight: bold;
    font-size: 18px;
    color: $color-nav;
  }

  .admin-tools .table-responsive {
    height: 320px;
    border: 1px solid #dee2e6;
  }

  .admin-tools table {
    min-height: 20px !important;
  }

  #tableTicketTotal tr,
  #tableMonitoreoTotal tr {
    background-color: $color-nav;
  }

  #tableTicketTotal tr td,
  #tableMonitoreoTotal tr td {
    color: #fff;
    font-weight: 500;
  }



  #divTablesPrizes tbody:not(#tableTotal) {
    display: block;
    height: 35vh;
    max-height: 400px;
    overflow: auto;
    border: 1px solid #dee2e6 !important;
  }

  #divTablesPrizes thead,
  #divTablesPrizes tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*/
  }

  //  style of scroll bar
  #divTablesPrizes tbody::-webkit-scrollbar {
    width: 5px;
  }

  #divTablesPrizes tbody::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  #divTablesPrizes tbody::-webkit-scrollbar-thumb {
    background-color: #cc2f48;
    border-radius: 0px 0px 50px 50px;
  }

  #divTablesPrizes tr {
    border-color: #dee2e6 !important;
  }

  #tableTotal tr,
  tr.consorcioActive,
  #tableMonitor tr.active,
  .tr-active {
    background: linear-gradient(180deg,
        #ffdbb5 0%,
        #ffb65a 42%,
        #ffe794 85%,
        #fff 100%);
  }

  #tableConsorciosCentral {
    min-height: 30px !important;
  }

  #tableTotal tr td {
    color: #181873 !important;
    font-weight: bold;
  }

  .spanActiveBoat {
    display: block;
    border: 1px solid #529a10;
    background: linear-gradient(120deg, #f7fbe7 0%, #73b631 100%);
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  #RowTablesList .table-responsive,
  .table-custom-bordered {
    border: 1px solid #dee2e6 !important;
  }

  #createGroupModal .nav-tabs li button {
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    padding: 5px 5px;
    margin: 0 5px;
    color: $color-nav;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    text-align: center;
    width: 100%;
  }

  #createGroupModal .nav-tabs li button.active {
    background-color: $color-nav;
    border: 2px solid $color-nav;
    color: #fff;
  }

  #createGroupModal .nav-pills li button,
  #editUser .nav-pills li button {
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 5px;
    margin: 0 5px;
    color: $color-nav;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }

  #createGroupModal .nav-pills li button.active,
  #editUser .nav-pills li button.active {
    background-color: $color-nav;
    border: 2px solid $color-nav;
    color: #fff;
  }

  @media only screen and (min-width: 1900px) {
    body {
      zoom: 170%;
    }
  }

  @media only screen and (min-width: 2800px) {
    body {
      zoom: 210%;
    }
  }

  .modal-header .btn-close {
    background-color: transparent !important;
    border: none !important;
    width: auto !important;
    padding: 0.5rem 0.5rem !important;
    margin: -0.5rem -0.5rem -0.5rem auto !important;
  }

  .form-check-input[type="checkbox"] {
    border-radius: 0.25em;
    margin-top: 5px !important;
    padding: 9px;
  }

  display: flex;



}

.button_disconnect {
  background-color: #3f333f !important;
  color: #f3f3f3 !important;
  width: fit-content !important;
  transition: all 0.2s ease-in-out;
  border: none !important;
}

@keyframes parpadeo {
  0%, 100% {
    opacity: 2;
  }
  50% {
    opacity: .3;
  }
}


.button_disconnect_isConnect {
  background-color: #90EE90 !important;
  color: #000000 !important;
  width: fit-content !important;
  transition: all 0.2s ease-in-out;
  border: none !important;
  animation: parpadeo 2s infinite;
}

.button_disconnect:hover {
  border: 1px #f3f3f3 solid !important;
  background-color: #f3f3f3 !important;
  color: #252525 !important;
  border: none !important;
  width: fit-content !important;
}

.button_block {
  background-color: transparent !important;
  color: #f3f3f3 !important;
  width: fit-content !important;
  border: 1px #22222d solid !important;
}

.button_block:hover {
  border: 1px #f3f3f3 solid !important;
  background-color: #f3f3f3 !important;
  color: #252525 !important;
  width: fit-content !important;
}

// .monitor_container {

//   // table style for monitor:
//   // bordered, striped, hover and responsive


// }

#table_container {

  .table-responsive {
    scroll-snap-type: y mandatory;
  }

  table {
    width: 100% !important;
    border: none !important;
    border-radius: 5px;
    margin-bottom: 0 !important;
    background-color: #fff;
    // border-collapse: collapse;
    // border-spacing: 0;
    // display: table;
    // table-layout: fixed;


    thead {
      // display: table;
      // width: 100%;
      // table-layout: fixed;
      border: none;
      background-color: #fff;
      color: #f3f3f3;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      // position: sticky;
      // top: 0;

      tr {
        // display: table;
        // width: 100%;
        // table-layout: fixed;


        th {
          // display: table-cell;
          // width: 100%;
          // table-layout: fixed;
          scroll-snap-align: start;
          padding: 0.75rem;
          vertical-align: top;
          background-color: #3f333f;
          border: none;
          color: #fff;
          font-weight: bold;
          font-size: 14px;
          text-align: center;
        }
      }
    }

    tbody {
      // display: table;
      // width: 100%;
      // table-layout: fixed;

      tr {
        // display: table;
        // width: 100%;
        // table-layout: fixed;

        td {
          // display: table-cell;
          // width: 100%;
          // table-layout: fixed;
          scroll-snap-align: start;
          padding: 0.4rem;
          vertical-align: top;
          border: none;
          text-align: center;
          color: #f3f3f3;
          font-weight: 500;
          font-size: 14px;

          select {
            background-color: transparent !important;
            font-size: 12px !important;
            color: #f3f3f3 !important;

            &:focus {
              background-color: transparent !important;
              color: #f3f3f3 !important;
            }

            option {
              background-color: #3f333f !important;
              color: #f3f3f3 !important;
            }
          }
        }
      }

      tr:nth-child(even) {
        background-color: #4b4f58;
      }

      tr:nth-child(even):hover {
        background-color: #2F4858;
      }

      tr:nth-child(odd):hover {
        background-color: #2F4858;
      }

      tr:nth-child(odd) {
        background-color: #33353f;
      }

      tr.row-selected {
        background: rgb(80, 71, 80);
        background: linear-gradient(180deg, rgba(80, 71, 80, 1) 0%, rgba(204, 47, 72, 1) 35%, rgba(115, 24, 24, 1) 100%);
      }
    }

    tfoot {
      // display: table;
      // width: 100%;
      // table-layout: fixed;
      border-top: 1px solid #dee2e6;
      background-color: #fff;
      color: #181873;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      position: sticky;
      bottom: -2px;

      tr {
        // display: table;
        // width: 100%;
        // table-layout: fixed;

        th {
          // display: table-cell;
          // width: 100%;
          // table-layout: fixed;
          padding: 0.75rem;
          vertical-align: top;
          border-top: 1px solid #dee2e6;
        }
      }
    }
  }
}

#selectConsorcioUsers {
  width: 500px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3f333f;
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;

  select {
    width: 100%;
    border: none;
    background-color: transparent !important;
    color: #3f333f;
    font-weight: 500;
    font-size: 16px;
    padding: 5px 5px;
    margin: 0 5px;
    color: $color-nav;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    width: 100%;

    &:focus {
      outline: none;
    }

    option {
      color: #3f333f;
      font-weight: 500;
      font-size: 16px;
    }


  }
}

.navbuttons_container {
  gap: 0 !important;

  button {
    // background-color: #dc3545 !important;
    border: #3f333f 1px solid !important;
    margin: 0 !important;
    padding: 5px 10px !important;
    border-radius: 0 !important;
    font-size: 16px !important;
  }

  button.active {
    background-color: #767dda !important;
    color: #f3f3f3 !important;
    border: #3f333f 1px solid !important;
  }
}

#cxcv2 {
  table {
    th {
      background-color: #3f333f !important;
      color: #f3f3f3 !important;
      font-weight: bold !important;
      font-size: 14px !important;
      text-align: center !important;
    }
  }
}

#btnSubmitIngresoEgreso {
  background-color: #3f333f !important;
  border-color: 2px solid #3f333f !important;

  &:hover {
    background-color: #665966 !important;
  }
}


@media only screen and (max-width: 1080px) {
  #containerHome #reportsContainer {
    zoom: 80%
  }

  #bodyAdmin #sidebar>div {
    zoom: 80%
  }
}

@media only screen and (max-width: 900px) {
  #containerHome #reportsContainer {
    zoom: 75%
  }
}

.white-space-nowrap {
  white-space: nowrap;
}


.custom-tfoot {
  td {
    padding: .5rem 1rem;
  }
}

.sticky-tfoot {
  position: sticky;
}

#divTablesPrizesv2 {
  height: 100% !important;
  min-height: 800px;
  display: flex;
  flex-wrap: wrap;
  gap: 0;

  // max-height: 70vh;
  >div:nth-child(1),
  >div:nth-child(2) {
    width: 50%;
    padding: 0 3px;
    height: fit-content;

    >div {
      width: 100%;
      // height: 100%;

      >table {
        width: 100%;
        // height: 100%;
        position: relative;
        border: 2px solid #3f333f !important;
        margin-top: 0;
        color: #f1f1f1;
        font-size: 14px;

        thead {

          tr th {
            position: sticky !important;
            top: 0 !important;
            background-color: #cc2f48;
          }

          ;
        }

        tbody {
          height: 35vh !important;
          max-height: 400px !important;
          overflow-y: auto !important;

          tr {
            &:nth-child(odd) {
              background-color: #1b1b1b;
            }

            &:nth-child(even) {
              background-color: #3f333f;
            }
          }
        }
      }
    }
  }

  >div:nth-child(3) {
    width: 100%;
    padding: 0 3px;

    >table {
      width: 100%;
      position: relative;
      border: 2px solid #3f333f !important;
      margin-top: 5px;
      color: #f1f1f1;

      thead {

        tr th {
          position: sticky !important;
          top: 0 !important;
          background-color: #cc2f48;
        }

        ;
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background-color: #1b1b1b;
          }

          &:nth-child(even) {
            background-color: #3f333f;
          }
        }
      }
    }
  }
}

#divTablesPrizesv2 tbody:not(#tableTotal) {
  display: block;
  height: 35vh;
  max-height: 400px;
  overflow: auto;
}

#divTablesPrizesv2 thead,
#divTablesPrizesv2 tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  /* even columns width , fix width of table too*/
}

//  style of scroll bar
#divTablesPrizesv2 tbody::-webkit-scrollbar {
  width: 5px;
}

#divTablesPrizesv2 tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#divTablesPrizesv2 tbody::-webkit-scrollbar-thumb {
  background-color: #cc2f48;
  border-radius: 0px 0px 50px 50px;
}

#divTablesPrizesv2 tr {
  border-color: #dee2e6 !important;
}