@media (max-width: 767px) {
    .headerContainer {
      width: 73vh !important;
    }
    .h3HeaderSupport {
      left: 120px !important;
    }
    .h1HeaderSupport {
        left: 120px !important;
    }
    .imgFondo{
        display: none;
        left:25px !important;


    }
    .imgMonitor {
        display: none;
        left: 48px !important;
    }
    .contenerLeftSupport{
        width: 400px !important;
    }
    .h1Support {
        left: 340px !important;
        top: -15px !important;
    }
    .h3Support {
        left: 300px !important;
    }
    .assigned {
        @media (max-width: 767px) {
            width: 70px !important;
        }
    }
  }
  